"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sleep = exports.headersInfo = exports.convertNumToHexString = void 0;
function convertNumToHexString(num) {
    return `0x${num.toString(16)}`;
}
exports.convertNumToHexString = convertNumToHexString;
function headersInfo(accessToken) {
    return {
        Authorization: `Bearer ${accessToken}`,
    };
}
exports.headersInfo = headersInfo;
function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
exports.sleep = sleep;
