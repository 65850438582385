"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.directParams = exports.BUFFER_TIMEOUT = exports.EVENT_TIMEOUT = exports.PASSPHRASE_EVENT = exports.TOKEN = exports.VERIFIER_ID_FIELD = exports.SECURITY_QUESTION = exports.DEFAULT_TOKEN_EXPIRY = exports.DEFAULT_LOGIN_TYPE = exports.DEFAULT_TOKEN_TYPE = exports.EIP155 = exports.WEB3AUTH_PROVIDER_NAME = void 0;
exports.WEB3AUTH_PROVIDER_NAME = "Web3Auth";
exports.EIP155 = "eip155";
exports.DEFAULT_TOKEN_TYPE = "Bearer";
exports.DEFAULT_LOGIN_TYPE = "jwt";
exports.DEFAULT_TOKEN_EXPIRY = 900;
exports.SECURITY_QUESTION = "what is your recovery passphrase";
exports.VERIFIER_ID_FIELD = "sub";
exports.TOKEN = "token";
exports.PASSPHRASE_EVENT = "set-passphrase";
exports.EVENT_TIMEOUT = 1000 * 60 * 3;
exports.BUFFER_TIMEOUT = 1000 * 5;
exports.directParams = {
    baseUrl: `${window.location.origin}/serviceworker`,
    enableLogging: false,
    network: "testnet",
    web3AuthClientId: "web3AuthClientId",
};
