import axios from "axios";
import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { Triangle } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import Header from "../../components/Layout/Header";
import { KeycloakContext } from "../../context/keycloak";
import { db } from "../../firebase/firebase";
import Gallery, { Group } from "./gallery"; // Import the Gallery component

function CreatorDetailPage() {
  const { robloxUserId } = useParams();
  const [groupName, setGroupName] = useState("");
  const [robloxGroupname, setRobloxGroupname] = useState<Group[]>([]); // Assuming Group interface is imported or defined
  const [groupItems, setGroupItems] = useState<any[]>([]); // State to store group items
  const [bestSellers, setBestSellers] = useState<any[]>([]); // State to store top three best sellers
  const [groupImages, setGroupImages] = useState<string[]>([]); // State to store group images
  const [groupInfo, setGroupInfo] = useState<string[]>([]);
  const [isCreator, setIsCreator] = useState<boolean>(false);
  const [dataStale, setDataStale] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const auth = useContext(KeycloakContext);
  const handleRefresh = async () => await auth?.refreshSession(-1);

  useEffect(() => {
    setLoading(true);
    async function initializePage() {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "users"),
            where("robloxUserId", "==", robloxUserId)
          )
        );
        if (querySnapshot.docs.length > 0) {
          const userData = querySnapshot.docs[0].data();
          let dataStale = false; // Initialize dataStale here
          // Check if userData contains the dataUpdated property
          if (!userData.dataUpdated) {
            // If dataUpdated doesn't exist, set the current time as the dataUpdated property
            console.log("Setting dataUpdated property for the first time.");
            await updateDoc(doc(db, "users", querySnapshot.docs[0].id), {
              dataUpdated: new Date().getTime(),
            });
            dataStale = true;
          } else {
            // If dataUpdated exists, check if it's been more than 4 hours
            const currentTime = new Date().getTime();
            const lastUpdatedTime = userData.dataUpdated;
            console.log(currentTime);
            console.log(lastUpdatedTime);

            if (currentTime - lastUpdatedTime >= 30 * 60 * 1000) {
              // If it's been more than 4 hours, log that update is ready
              console.log("Update ready. Fetching new data...");
              dataStale = true;
            } else {
              // If it's been less than 4 hours, log that there's no need to update
              console.log("No need to update. Data is up to date.");
            }
          }

          setGroupName(userData.robloxGroupname[0]?.groupName || "");
          setRobloxGroupname(userData.robloxGroupname || []);

          const account = JSON.parse(localStorage.getItem("account") || "");
          if (account) {
            const email = account.email;
            const querySnapshot = await getDocs(
              query(collection(db, "users"), where("email", "==", email))
            );
            if (querySnapshot.docs.length > 0) {
              const user = querySnapshot.docs[0].data();
              if (user.robloxUserId == robloxUserId) {
                setIsCreator(true);
              }
            }
          }

          setDataStale(dataStale);

          // Fetch group items for each group ID
          const groupItemsPromises = userData.robloxGroupname.map(
            async (group: any) => {
              let groupItemsData;
              if (dataStale) {
                console.log("Pulling updates...");
                if (group.groupName === "Player inventory") {
                  console.log("player inventory found");
                  groupItemsData = await getUserItems(robloxUserId || "");
                  console.log(groupItemsData);
                  if (groupItemsData.length === 0) {
                    console.log("its zero");
                  }
                } else {
                  console.log("updating...");
                  groupItemsData = (await getGroupItems(group.groupId)) || [];
                  if (groupItemsData.length === 0) {
                    console.log("its zero");
                  }
                }

                await updateDoc(doc(db, "users", querySnapshot.docs[0].id), {
                  dataUpdated: new Date().getTime(),
                });
              } else {
                groupItemsData = group.items || [];
                if (groupItemsData.length === 0) {
                  console.log("its zero");
                }
              }

              const itemIds = groupItemsData
                .filter((item: any) => item && item.id)
                .map((item: any) => item.id);
              const itemImagePromises = itemIds.map((itemId: string) =>
                getItemImage(itemId)
              );
              const itemImages = await Promise.all(itemImagePromises);
              const groupItemsWithImages = groupItemsData.map(
                (item: any, index: number) => ({
                  ...item,
                  url: itemImages[index],
                })
              );
              await initializeDatabase(group.groupId, groupItemsWithImages);
              return groupItemsWithImages;
            }
          );
          const groupItemsData = await Promise.all(groupItemsPromises);
          setGroupItems(groupItemsData);

          const topThreeItems = groupItemsData.map((items) => {
            // Sort the items based on purchase count in descending order
            items.sort((a: any, b: any) => b.purchaseCount - a.purchaseCount);
            // Take the first three items
            return items.slice(0, 3);
          });

          setBestSellers(topThreeItems);

          // Fetch group images for each group ID
          const groupImagePromises = userData.robloxGroupname.map(
            async (group: any) => {
              if (group.groupName === "Player inventory") {
                return await getFullAvatar(robloxUserId || "");
              } else {
                const groupImage = await getGroupImage(group.groupId);
                return groupImage;
              }
            }
          );
          const groupImages = await Promise.all(groupImagePromises);
          setGroupImages(groupImages);

          // Fetch group info for each group ID
          const groupInfoPromises = userData.robloxGroupname.map(
            async (group: any) => {
              let groupInfo;
              if (group.groupName === "Player inventory") {
              } else {
                groupInfo = await getGroupInfo(group.groupId);
              }

              return groupInfo;
            }
          );
          const groupInfo = await Promise.all(groupInfoPromises);
          setGroupInfo(groupInfo);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching group name:", error);
        setLoading(false);
      }
    }

    initializePage();
  }, []);

  async function getToggles(
    itemId: string
  ): Promise<{
    itemId: string;
    isVisible: boolean;
    isCustomizable: boolean;
  } | null> {
    try {
      // Fetch the user document containing robloxGroupname
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("robloxUserId", "==", robloxUserId)
        )
      );
      const userDoc = querySnapshot.docs[0];

      // Find the item in robloxGroupname array with the given itemId
      const groupWithItem = userDoc
        .data()
        ?.robloxGroupname.find((group: Group) => {
          // Check if the group has items
          if (!Array.isArray(group.items) || group.items.length === 0) {
            console.log(
              `Skipping search for group ${group.groupName} because it has no items.`
            );
            return false; // Skip the search for this group
          }
          // Check if any item in the group matches the itemId
          return group.items.some((item) => Number(item.id) === Number(itemId));
        });

      // If groupWithItem exists, find the item with the given itemId and return its properties
      if (groupWithItem) {
        const item = groupWithItem.items.find(
          (item: { id: string; isVisible: boolean; isCustomizable: boolean }) =>
            Number(item.id) === Number(itemId)
        );
        if (item) {
          return {
            itemId: itemId,
            isVisible: item.isVisible,
            isCustomizable: item.isCustomizable,
          };
        }
      }

      // If item with itemId is not found, return null
      return null;
    } catch (error) {
      console.error("Error fetching item properties:", error);
      return null;
    }
  }

  async function updateDatabase(
    groupId: string,
    items: { itemId: string; isVisible?: boolean; isCustomizable?: boolean }[]
  ) {
    try {
      // Perform a Firestore query to find the user document based on the Roblox user ID
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("robloxUserId", "==", robloxUserId)
        )
      );

      // Check if the query returned any documents
      if (!querySnapshot.empty) {
        // Get the first document (assuming there's only one user document per Roblox user)
        const userDoc = querySnapshot.docs[0];
        const userId = userDoc.id; // Get the user document ID

        // Update the user document with the new items array for the specified groupId
        await setDoc(
          doc(db, "users", userId),
          {
            robloxGroupname: userDoc
              .data()
              .robloxGroupname.map((group: any) => {
                if (group.groupId === groupId) {
                  // If the group matches the specified groupId, update its items array
                  const updatedItems = group.items
                    ? group.items.map((existingItem: any) => {
                        // Find the corresponding item in the items array
                        const newItem = items.find(
                          (item) => item.itemId === existingItem.id
                        );
                        if (newItem) {
                          // If the item exists in the new items array, compare its properties with the existing ones
                          return {
                            ...existingItem,
                            isVisible:
                              newItem.isVisible !== undefined &&
                              newItem.isVisible !== existingItem.isVisible
                                ? newItem.isVisible
                                : existingItem.isVisible,
                            isCustomizable:
                              newItem.isCustomizable !== undefined &&
                              newItem.isCustomizable !==
                                existingItem.isCustomizable
                                ? newItem.isCustomizable
                                : existingItem.isCustomizable,
                          };
                        } else {
                          // If the item doesn't exist in the new items array, return it unchanged
                          return existingItem;
                        }
                      })
                    : [];
                  return { ...group, items: updatedItems };
                } else {
                  return group;
                }
              }),
          },
          { merge: true }
        );

        console.log("Group items successfully updated in the database!");
      } else {
        console.error(
          "User document not found for Roblox user ID:",
          robloxUserId
        );
      }
    } catch (error) {
      console.error("Error updating group items in the database:", error);
    }
  }

  async function initializeDatabase(
    groupId: string,
    items: { id: string; isVisible?: boolean; isCustomizable?: boolean }[]
  ) {
    try {
      // Perform a Firestore query to find the user document based on the Roblox user ID
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("robloxUserId", "==", robloxUserId)
        )
      );

      // Check if the query returned any documents
      if (!querySnapshot.empty) {
        // Get the first document (assuming there's only one user document per Roblox user)
        const userDoc = querySnapshot.docs[0];
        const userId = userDoc.id; // Get the user document ID

        // Initialize the items array with default values for the specified groupId
        // Filter out items that already exist in the database
        const existingItems =
          userDoc
            .data()
            .robloxGroupname.find((group: any) => group.groupId === groupId)
            ?.items || [];
        const newItems = items.filter(
          (newItem) =>
            !existingItems.some(
              (existingItem: any) => existingItem.id === newItem.id
            )
        );
        // Filter out items from existingItems that do not exist in items
        const itemsToRemove = existingItems.filter(
          (existingItem: any) =>
            !items.some((item) => item.id === existingItem.id)
        );

        // Update the user document with the initialized items array for the specified groupId
        await setDoc(
          doc(db, "users", userId),
          {
            robloxGroupname: userDoc
              .data()
              .robloxGroupname.map((group: any) => {
                if (group.groupId === groupId) {
                  // If the group matches the specified groupId
                  const updatedItems = group.items
                    ? [...group.items, ...newItems]
                    : newItems;
                  const mergedItems = updatedItems.map((newItem: any) => {
                    const existingItem = existingItems.find(
                      (item: any) => item.itemId === newItem.itemId
                    );
                    if (existingItem) {
                      // Merge existing item with new item, preserving existing isVisible and isCustomizable values
                      const updatedItem = { ...existingItem, ...newItem };
                      if (!newItem.hasOwnProperty("isVisible")) {
                        updatedItem.isVisible = existingItem.isVisible || false; // Preserve existing isVisible value if not explicitly defined in newItem
                      }
                      if (!newItem.hasOwnProperty("isCustomizable")) {
                        updatedItem.isCustomizable =
                          existingItem.isCustomizable || false; // Preserve existing isCustomizable value if not explicitly defined in newItem
                      }
                      return updatedItem;
                    } else {
                      return newItem;
                    }
                  });
                  return { ...group, items: mergedItems };
                } else {
                  return group;
                }
              }),
          },
          { merge: true }
        );

        console.log("Group items successfully initialized in the database!");
      } else {
        console.error(
          "User document not found for Roblox user ID:",
          robloxUserId
        );
      }
    } catch (error) {
      console.error("Error initializing group items in the database:", error);
    }
  }

  // Function to fetch groupImage based on user ID
  async function getGroupImage(groupId: string) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/groupImage/${groupId}`
      );
      return response.data.data[0].imageUrl;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  // Function to fetch groupInfo based on user ID
  async function getGroupInfo(groupId: string) {
    try {
      // Make a GET request to fetch group info
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/groupInfo/${groupId}`
      );
      const groupInfo = response.data;

      // Get the avatar URL for the group creator
      const avatarURL = await getFullAvatar(groupInfo.owner.userId);

      // Add the avatarURL to the groupInfo object
      groupInfo.avatarURL = avatarURL;

      return groupInfo;
    } catch (error) {
      console.error("Error fetching group info:", error);
    }
  }

  async function getUserItems(creatorId: string) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/creatorItems/${creatorId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  // Function to fetch username based on user ID
  async function getGroupItems(groupId: string) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/groupItems/${groupId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  // Function to fetch username based on user ID
  async function getItemImage(itemId: string) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/itemImage/${itemId}`
      );
      return response.data.data[0].imageUrl;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  // Function to fetch username based on user ID
  async function getFullAvatar(userId: string) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/fullAvatar/${userId}`
      );
      return response.data.data[0].imageUrl;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  return (
    <div className='main-area'>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Triangle color='#00BFFF' height={200} width={200} />
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <Header />
          <Gallery
            robloxGroupname={robloxGroupname}
            bestSellers={bestSellers}
            allItems={groupItems}
            groupImages={groupImages}
            groupInfo={groupInfo}
            updateDatabase={updateDatabase}
            getToggles={getToggles}
            isCreator={isCreator}
            creatorId={robloxUserId || ""}
          />
        </>
      )}
    </div>
  );
}

export default CreatorDetailPage;
