import { Button, ConfigProvider } from "antd";
import ButtonTheme from "../../theme/ButtonTheme";
import { FC } from "react";

type IProps = {
  icon: React.ReactNode;
  text: React.ReactNode;
  onClick: () => void;
};

const CSDefaultButton: FC<IProps> = ({ icon, text, onClick }) => {
  return (
    <ConfigProvider theme={ButtonTheme}>
      <Button
        onClick={onClick}
        block
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
        }}
      >
        {icon && icon}
        {text}
      </Button>
    </ConfigProvider>
  );
};

export default CSDefaultButton;
