import { createBrowserRouter } from "react-router-dom";
import DashboardPage from "../pages/Dashboard";
import LoginPage from "../pages/Login";
import SignUpPage from "../pages/SignUp";
import CreatorsPage from "../pages/Creators";
import CreatorDetailPage from "../pages/CreatorDetail";
import RewardPage from "../pages/Reward";
import PlayerPage from "../pages/Players";
import PlayerDetailPage from "../pages/PlayerDetail";
import CheckoutPage from "../pages/Checkout";
import CheckoutSuccessPage from "../pages/Checkout/CheckoutSuccess";
import CheckoutFailedPage from "../pages/Checkout/CheckoutFailed";
import Admin from "../pages/AdminPortal";
import TGA from "../pages/TGA/tga";
import TGALogin from "../pages/TGA/tgalogin";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/sign-up",
    element: <SignUpPage />,
  },
  {
    path: "/creators",
    element: <CreatorsPage />,
  },
  {
    path: "/players",
    element: <PlayerPage />,
  },
  {
    path: "/players/:robloxUserId?",
    element: <PlayerDetailPage />,
  },
  {
    path: "/rewards",
    element: <RewardPage />,
  },
  {
    path: "/creator/:robloxUserId",
    element: <CreatorDetailPage />,
  },
  {
    path: "/checkout/:itemId/:itemCreator/:creatorId/:itemName/:itemPrice/:itemQuantity",
    element: <CheckoutPage />,
  },

  {
    path: "/checkout-success",
    element: <CheckoutSuccessPage />,
  },
  {
    path: "/checkout-failed",
    element: <CheckoutFailedPage />,
  },
  {
    path: "/admin",
    element: <Admin />,
  },
  {
    path: "/tga",
    element: <TGA />,
  },
  {
    path: "/tgalogin",
    element: <TGALogin />,
  }
]);
