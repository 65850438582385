"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPassphraseEvent = exports.convertToCustomChainConfig = void 0;
const constant_1 = require("./constant");
function convertToCustomChainConfig(networkConfig) {
    return {
        chainId: `0x${networkConfig.chainId.toString(16)}`,
        blockExplorer: networkConfig.explorer,
        displayName: networkConfig.networkName,
        rpcTarget: networkConfig.networkRpcUrl,
        ticker: networkConfig.chainSymbol,
        tickerName: networkConfig.chainSymbol.toLocaleUpperCase(),
        chainNamespace: constant_1.EIP155,
    };
}
exports.convertToCustomChainConfig = convertToCustomChainConfig;
function createPassphraseEvent(callback) {
    return new CustomEvent(constant_1.PASSPHRASE_EVENT, {
        detail: {
            callback,
            timeoutVal: constant_1.EVENT_TIMEOUT,
        },
    });
}
exports.createPassphraseEvent = createPassphraseEvent;
