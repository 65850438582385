import { ConfigProvider, Segmented } from "antd";
import SegmentedTheme from "../../theme/SegmentedTheme";
import { FC } from "react";

type IProps = {
  options: string[];
  value: string;
  setValue: (e: string) => void;
};

const CSSegmented: FC<IProps> = ({ options, value, setValue }) => {
  return (
    <ConfigProvider theme={SegmentedTheme}>
      <Segmented<string>
        options={options}
        onChange={(value) => {
          console.log(value); // string
          setValue(value);
        }}
        value={value}
        style={{
          width: 611,
          padding: "10px 12px",
          background: "#1D1838",
          borderRadius: 8,
          border: "1px solid #FBC7FF",
          maxWidth: "100%",
        }}
        block
      />
    </ConfigProvider>
  );
};

export default CSSegmented;
