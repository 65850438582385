import type { ThemeConfig } from "antd";

const ButtonTheme: ThemeConfig = {
  components: {
    Button: {
      defaultBg: "#1D1838",
      borderRadius: 8,
      defaultBorderColor: "#FBC7FF",
      controlHeight: 56,
      defaultHoverBg: "transparent",
      defaultColor: "#F579FF",
      fontSize: 18,
    },
  },
};

export default ButtonTheme;
