"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractWallet = void 0;
const zbyte_common_1 = require("@zbyteio/zbyte-common");
const common_1 = require("../common");
const errors_1 = require("../errors");
const ethers_1 = require("ethers");
class AbstractWallet {
    nativeNetworkConfig;
    walletProvider;
    constructor(walletProvider) {
        this.walletProvider = walletProvider;
        this.nativeNetworkConfig = (0, zbyte_common_1.getBlockchainNetwork)(common_1.NATIVE_CHAIN_ID);
    }
    getFaucetDripAccount = async () => {
        const faucetContract = new ethers_1.ethers.Contract(zbyte_common_1.DPLAT_FAUCET_CONTRACT_HASH, [
            {
                inputs: [],
                name: "faucetTokenDripAmount",
                outputs: [
                    {
                        internalType: "uint256",
                        name: "",
                        type: "uint256",
                    },
                ],
                stateMutability: "view",
                type: "function",
            },
        ], ethers_1.ethers.getDefaultProvider(this.nativeNetworkConfig.networkRpcUrl));
        const dripAmount = await faucetContract.faucetTokenDripAmount();
        return ethers_1.ethers.utils.formatEther(dripAmount.toString());
    };
    getTokenBalance = async (address) => {
        try {
            if (!address) {
                address = await this.getAddress();
            }
            const erc20ContractHash = this.nativeNetworkConfig.networkType == zbyte_common_1.TESTNET
                ? zbyte_common_1.DPLAT_ERC20_CONTRACT_HASH
                : zbyte_common_1.DPLAT_ERC20_MAINNET_CONTRACT_HASH; // Need to replace with mainnet contract
            const ERC20Contract = new ethers_1.ethers.Contract(erc20ContractHash, [
                {
                    inputs: [
                        {
                            internalType: "address",
                            name: "account",
                            type: "address",
                        },
                    ],
                    name: "balanceOf",
                    outputs: [
                        {
                            internalType: "uint256",
                            name: "",
                            type: "uint256",
                        },
                    ],
                    stateMutability: "view",
                    type: "function",
                },
            ], ethers_1.ethers.getDefaultProvider(this.nativeNetworkConfig.networkRpcUrl));
            const balance = await ERC20Contract.balanceOf(address);
            return balance.toString();
        }
        catch (error) {
            throw new errors_1.WalletError(errors_1.ErrorCode.WALLET_GET_TOKEN_BALANCE_FAILED, error);
        }
    };
}
exports.AbstractWallet = AbstractWallet;
