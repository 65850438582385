import React, { useState, useEffect } from "react";
import { Switch, Modal } from "antd";
import "./style.scss";
import ProductCard from "../../components/ProductCard";

type IProps = {
  buttonEnabled: boolean;
  groupId: string;
  itemId: string;
  updateDatabase: (
    groupId: string,
    items: { itemId: string; isVisible: boolean; isCustomizable: boolean }[]
  ) => void;
  itemName: string;
  isPublic: boolean;
  isCustomizable: boolean;
  imageUrl: string;
  itemInfo: {
    itemPrice: string;
    itemCreator: string;
    totalQuantity: string;
    unitsAvailableForConsumption: string;
  };
  creatorId: string;
};

const SponsorCard: React.FC<IProps> = ({
  buttonEnabled,
  groupId,
  itemId,
  updateDatabase,
  itemName,
  isCustomizable: initialIsCustomizable,
  isPublic: initialIsPublic,
  imageUrl,
  itemInfo,
  creatorId,
}: IProps) => {
  const [isCustomizable, setIsCustomizable] = useState(initialIsCustomizable);
  const [isPublic, setIsPublic] = useState(initialIsPublic);
  const [showModal, setShowModal] = useState(false);

  // Update state when props change
  useEffect(() => {
    console.log(isPublic);
    setIsCustomizable(initialIsCustomizable);
    setIsPublic(initialIsPublic);
  }, [initialIsCustomizable, initialIsPublic]);

  const handlePublicToggle = (checked: boolean) => {
    // Update the isPublic state and call the updateDatabase function
    setIsPublic(checked);
    updateDatabase(groupId, [
      { itemId, isVisible: checked, isCustomizable: isCustomizable },
    ]);
  };

  const handleCustomizedToggle = (checked: boolean) => {
    // Update the isCustomized state and call the updateDatabase function
    setIsCustomizable(checked);
    updateDatabase(groupId, [
      { itemId, isVisible: isPublic, isCustomizable: checked },
    ]);
  };

  const handleCardClick = () => {
    // Toggle the visibility of the modal
    if (localStorage.getItem("playerType") === "Sponsor") {
      setShowModal(!showModal);
    }
  };

  return (
    <>
      <div
        className={`sponsor-card-item`}
        style={{ height: buttonEnabled ? "auto" : 301, marginBottom: 20 }}
        onClick={handleCardClick}
      >
        <div className='card-holder'>
          <div className='image-holder'>
            <img src={imageUrl} alt={itemName} className='card-image' />
          </div>

          <div
            className='card-info'
            style={{ height: buttonEnabled ? 300 : 150 }}
          >
            <div
              className='card-text'
              style={{ marginLeft: 5, marginRight: 5 }}
            >
              {itemName}
            </div>
            <div className='card-text' style={{ fontFamily: "Lalezar" }}>
              TOTAL QUANTITY:
              <span style={{ fontFamily: "Inter" }}>
                {" "}
                {itemInfo.totalQuantity}
              </span>
            </div>
            <div
              className='card-text'
              style={{
                marginBottom: buttonEnabled ? 0 : 15,
                fontFamily: "Lalezar",
              }}
            >
              DISTRIBUTED:{" "}
              <span style={{ fontFamily: "Inter" }}>
                {itemInfo.unitsAvailableForConsumption}
              </span>
            </div>
            {buttonEnabled && (
              <div className='card-toggle-button-collection'>
                <div className='card-toggle-button'>
                  <div>PUBLIC</div>
                  <div>
                    <Switch checked={isPublic} onChange={handlePublicToggle} />
                  </div>
                </div>
                <div
                  className='card-toggle-button'
                  style={{ marginBottom: 15 }}
                >
                  <div>CUSTOMIZE</div>
                  <div>
                    <Switch
                      checked={isCustomizable}
                      onChange={handleCustomizedToggle}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <div className='modal-background' onClick={handleCardClick}>
          <div className='modal-content'>
            <ProductCard
              imageUrl={imageUrl}
              itemName={itemName}
              itemCreator={itemInfo.itemCreator}
              itemPrice={itemInfo.itemPrice}
              itemQuantity={itemInfo.totalQuantity}
              unitsAvailableForConsumption={
                itemInfo.unitsAvailableForConsumption
              }
              itemId={itemId}
              creatorId={creatorId}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SponsorCard;
