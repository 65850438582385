"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertErrorToAxiosError = exports.WalletError = void 0;
const errors_map_1 = require("./errors.map");
class WalletError extends Error {
    code;
    parentError;
    category;
    constructor(code, parentError) {
        const message = errors_map_1.errorMessageMap[code];
        super(message);
        this.name = "WalletError";
        this.code = code;
        this.category = errors_map_1.errorCategoryMap[code];
        this.parentError = new Error("");
        this.parentError.message = parentError?.message ?? "";
        this.parentError.name = parentError?.name ?? "";
        this.parentError.stack = parentError?.stack;
        this.printError();
    }
    printError = () => {
        const error = {
            errorCode: this.code,
            errorMessage: this.message,
            errorCategory: this.category,
            internalError: this.parentError.message,
        };
        console.error(JSON.stringify(error, null, 4));
    };
}
exports.WalletError = WalletError;
function convertErrorToAxiosError(error) {
    const axiosErr = error;
    if (axiosErr.response !== undefined) {
        return new Error(JSON.stringify(axiosErr.response?.data, null, 4));
    }
    else {
        return new Error(axiosErr.message);
    }
}
exports.convertErrorToAxiosError = convertErrorToAxiosError;
