"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SAFE_TRANSFER_FROM_ABI = exports.configServiceURLs = exports.NATIVE_CHAIN_ID = exports.DUPLICATE_DOC = exports.NOT_FOUND = exports.TORUS_METADATA_URL = void 0;
const zbyte_common_1 = require("@zbyteio/zbyte-common");
exports.TORUS_METADATA_URL = "https://metadata.tor.us";
exports.NOT_FOUND = {
    ERROR_CODE: 8006,
    ERROR_MESSAGE: "Document not found",
};
exports.DUPLICATE_DOC = {
    ERROR_CODE: 8010,
    ERROR_MESSAGE: "Conflict while creating document, seems like we found duplicate document",
};
exports.NATIVE_CHAIN_ID = zbyte_common_1.CHAIN_ID_MATIC_TESTNET;
exports.configServiceURLs = {
    STORE_PASSPHRASE_URL: "/config-service/api/v1/configs/security-shares/passphrase",
    FETCH_PASSPHRASE_URL: "/config-service/api/v1/configs/security-shares",
    ADD_NFT_ADDRESS_MAPPING_URL: "/config-service/api/v1/configs/user-wallet/user-wallet-address",
    ADD_NFT_USER_DETAIL_URL: "/config-service/api/v1/configs/user-wallet/user-wallet-detail",
    NFT_BOOKMARK_URL: "/config-service/api/v1/configs/user-wallet",
};
exports.SAFE_TRANSFER_FROM_ABI = [
    {
        inputs: [
            {
                internalType: "address",
                name: "from",
                type: "address",
            },
            {
                internalType: "address",
                name: "to",
                type: "address",
            },
            {
                internalType: "uint256",
                name: "tokenId",
                type: "uint256",
            },
        ],
        name: "safeTransferFrom",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
    },
];
