import React, { useEffect } from "react";
import { Button } from "antd";

function CheckoutSuccessPage() {
  useEffect(() => {

    // Send a message to the parent window
    window.opener.postMessage("Success", "*");
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <h1>Checkout Complete</h1>
      <p>Your payment was successful!</p>
      <Button type="primary" onClick={() => window.close()}>
        Close
      </Button>
    </div>
  );
}

export default CheckoutSuccessPage;