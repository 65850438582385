import React, { useEffect, useState } from "react";
import Header from "../../components/Layout/Header";
import { Button, ColorPicker, Input, Select } from "antd";
import "./style.scss";
import TextArea from "antd/es/input/TextArea";
import { useParams } from "react-router-dom";
import { db } from "../../firebase/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import axios from "axios";

function CheckoutPage() {
  const { itemId, itemCreator, itemName, itemPrice, itemQuantity, creatorId } =
    useParams();
  const [quantity, setQuantity] = useState("");
  const [description, setDescription] = useState("");
  const [claimURL, setClaimURL] = useState("");
  const [irlRewardDescription, setIrlRewardDescription] = useState("");
  const [yourPrice, setYourPrice] = useState("");
  const [isFormFilled, setIsFormFilled] = useState(false);
  const defaultClaimURL = "http://localhost:3000";

  useEffect(() => {
    // Check if all input forms are filled
    setIsFormFilled(
      !!quantity && !!description && !!irlRewardDescription && !!yourPrice
    );
  }, [quantity, description, irlRewardDescription, yourPrice]);

  const handleQuantityChange = (e: { target: { value: any } }) => {
    const inputValue = e.target.value;
    setQuantity(inputValue);
  };

  const exceededMax = parseInt(quantity) > parseInt(itemQuantity || "0");

  const handleAddToCart = async () => {
    if (!itemId || !itemName) {
      console.error("Item ID or name is not defined");
      return;
    }

    const paymentResult = await makePayment(yourPrice, itemName);
    const popup = window.open(
      paymentResult.url,
      "_blank",
      "width=600,height=400"
    );
    const sessionId = paymentResult.sessionId;

    if (!popup) {
      return;
    }

    // Define an async function to handle the message from the popup
    const handlePopupMessage = async (eventData: string) => {
      const sponsorName = JSON.parse(
        localStorage.getItem("account") || ""
      ).email;
      const sponsorCompany = await getCompanyName();
      // Log the message received from the popup
      if (
        eventData === "Success" &&
        (await checkPayment(sessionId)).paymentStatus === "paid"
      ) {
        try {
          const sponsorshipDocRef = doc(db, "Sponsorships", itemId);
          await setDoc(sponsorshipDocRef, {
            itemId,
            sponsor: sponsorName,
            sponsorName: sponsorCompany,
            itemName,
            itemCreator,
            quantity,
            description,
            irlRewardDescription,
            yourPrice,
            claimURL,
            status: "Pending",
            payment: "Success",
            creatorId: creatorId,
          });
          console.log("Document written with ID: ", itemId);
          const checkPopupClosed = setInterval(() => {
            if (popup.closed) {
              clearInterval(checkPopupClosed);
              // Popup closed, perform your actions here
              console.log("Popup closed");
              window.location.href = "/creators";
              // For example, you can fetch data from your backend to check if the payment was successful
            }
          }, 1000);
          // Redirect or perform any other actions after adding to cart
        } catch (error) {
          console.error("Error adding document: ", error);
        }
      }
      console.log("Message from popup:", eventData);
      // You can perform further actions based on the message content
    };

    // Listen for messages from the popup window
    window.addEventListener("message", (event) => {
      // Check if the message is from the popup window
      if (event.source === popup) {
        // Call the async function to handle the message
        handlePopupMessage(event.data);
      }
    });
  };

  async function makePayment(price: string, name: string) {
    try {
      // Make a GET request to fetch groups
      const response = await axios.post(
        `https://ugc-verse-backend.vercel.app/checkout/${price}/${name}`
      );
      // Filter the response data to include only the groups where the role is "Owner"
      return response.data;
    } catch (error) {
      console.error("Error making payment:", error);
      return []; // Return an empty array in case of error
    }
  }

  async function checkPayment(sessionID: string) {
    try {
      // Make a GET request to fetch groups
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/check-payment-status/${sessionID}`
      );
      // Filter the response data to include only the groups where the role is "Owner"
      return response.data;
    } catch (error) {
      console.error("Error making payment:", error);
      return []; // Return an empty array in case of error
    }
  }

  async function getCompanyName() {
    try {
      const account = JSON.parse(localStorage.getItem("account") || "");
      const email = account.email;
      // Perform a Firestore query to find the user document based on the Roblox user ID
      const querySnapshot = await getDocs(
        query(collection(db, "users"), where("email", "==", email))
      );

      // Check if the query returned any documents
      if (!querySnapshot.empty) {
        // Get the first document (assuming there's only one user document per Roblox user)
        const userDoc = querySnapshot.docs[0];
        const userId = userDoc.id; // Get the user document ID
        // Get the current items array from the user document
        const userData = userDoc.data();
        const companyName = userData.companyName;
        return companyName;
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleClaimURLChange = (e: { target: { value: any } }) => {
    const input = e.target.value;
    // If the input does not start with 'https://', add it
    if (!input.startsWith("https://")) {
      setClaimURL(`https://${input}`);
    } else {
      setClaimURL(input);
    }
  };

  return (
    <div className='main-area'>
      <Header />
      <div className='main-title-xl'>Checkout</div>
      <div className='main-title-m'>{itemName}</div>
      <div className='checkout-form-area'>
        <div className='left-part'>
          <div className='input-area'>
            <div className='input-title'>Quantity of the limited</div>
            <div className='input-content'>
              <Input
                name='quantity'
                placeholder={`Enter number of quantity (max: ${itemQuantity})`}
                value={quantity}
                onChange={handleQuantityChange}
                type='number'
                min={0}
              />
              {exceededMax && (
                <div
                  style={{
                    color: "red",
                    marginTop: "5px",
                    textAlign: "center",
                  }}
                >
                  Maximum quantity exceeded
                </div>
              )}
            </div>
          </div>
          <div className='input-area'>
            <div className='input-title'>Description of the item</div>
            <div className='input-content'>
              <TextArea
                placeholder='Description of the item'
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>
          <div className='input-area'>
            <div className='input-title'>Link to claim reward</div>
            <div className='input-content'>
              <Input
                placeholder='URL to claim item'
                value={claimURL}
                onChange={handleClaimURLChange}
              />
            </div>
          </div>
          <div className='input-area'>
            <div className='input-title'>IRL Reward Description</div>
            <div className='input-content'>
              <TextArea
                placeholder='IRL Reward Description'
                rows={3}
                value={irlRewardDescription}
                onChange={(e) => setIrlRewardDescription(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='right-part'>
          <div className='input-area'>
            <div className='input-title'>Price of Limited</div>
            <div className='input-content'>
              <Input value={itemPrice} />
            </div>
          </div>
          <div className='input-area'>
            <div className='input-title'>Release Date</div>
            <div className='input-content'>
              <Input type='date' placeholder='Release Date' />
            </div>
          </div>
          <div className='input-area'>
            <div className='input-title'>Your Price</div>
            <div className='input-content'>
              <Input
                type='number'
                placeholder='Your Price'
                value={yourPrice}
                onChange={(e) => setYourPrice(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className='input-content'>
          <Button
            type='primary'
            style={{ width: 273, height: 48, marginTop: 20 }}
            className='signup-btn'
            onClick={handleAddToCart}
            disabled={!isFormFilled} // Disable button if form is not filled
          >
            Checkout
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CheckoutPage;
