"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowType = exports.TxnChain = void 0;
var TxnChain;
(function (TxnChain) {
    TxnChain["avax"] = "Avalanche";
    TxnChain["matic"] = "Polygon";
    TxnChain["hbar"] = "Hedera";
    TxnChain["base"] = "Base";
})(TxnChain || (exports.TxnChain = TxnChain = {}));
var WorkflowType;
(function (WorkflowType) {
    WorkflowType["INVOKE"] = "invoke";
    WorkflowType["DEPLOY"] = "deploy";
})(WorkflowType || (exports.WorkflowType = WorkflowType = {}));
