"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.errorCategoryMap = exports.errorMessageMap = exports.ErrorCategory = exports.ErrorCode = void 0;
var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["WALLET_NOT_CONNECTED"] = 1001] = "WALLET_NOT_CONNECTED";
    ErrorCode[ErrorCode["WALLET_SET_NETWORK_FAILED"] = 1002] = "WALLET_SET_NETWORK_FAILED";
    ErrorCode[ErrorCode["SIGNATURE_FAILED"] = 1003] = "SIGNATURE_FAILED";
    ErrorCode[ErrorCode["GET_KEY_PROVIDER_FAILED"] = 1004] = "GET_KEY_PROVIDER_FAILED";
    ErrorCode[ErrorCode["NOT_IMPLEMENTED"] = 1005] = "NOT_IMPLEMENTED";
    ErrorCode[ErrorCode["NOT_INITIALIZED"] = 1006] = "NOT_INITIALIZED";
    ErrorCode[ErrorCode["TRANSACTION_NOT_FOUND"] = 1007] = "TRANSACTION_NOT_FOUND";
    ErrorCode[ErrorCode["INSUFFICIENT_BALANCE"] = 1008] = "INSUFFICIENT_BALANCE";
    ErrorCode[ErrorCode["TRIGGER_LOGIN_ERROR"] = 1009] = "TRIGGER_LOGIN_ERROR";
    ErrorCode[ErrorCode["FAILED_GENERATION_PASSPHRASE"] = 1010] = "FAILED_GENERATION_PASSPHRASE";
    ErrorCode[ErrorCode["FAILED_TO_FETCH_PASSPHRASE"] = 1011] = "FAILED_TO_FETCH_PASSPHRASE";
    ErrorCode[ErrorCode["FAILED_TO_UPDATE_PASSPHRASE"] = 1012] = "FAILED_TO_UPDATE_PASSPHRASE";
    ErrorCode[ErrorCode["INCORRECT_PASSPHRASE"] = 1013] = "INCORRECT_PASSPHRASE";
    ErrorCode[ErrorCode["FAILED_TO_FETCH_TOKENS"] = 1014] = "FAILED_TO_FETCH_TOKENS";
    ErrorCode[ErrorCode["FAILED_TO_FETCH_TRANSACTIONS"] = 1015] = "FAILED_TO_FETCH_TRANSACTIONS";
    ErrorCode[ErrorCode["INVALID_PRIVATE_KEY"] = 1016] = "INVALID_PRIVATE_KEY";
    ErrorCode[ErrorCode["TOO_MANY_FETCH_TOKEN_REQUEST"] = 1017] = "TOO_MANY_FETCH_TOKEN_REQUEST";
    ErrorCode[ErrorCode["ESTIMATE_TOKEN_FAILED"] = 1018] = "ESTIMATE_TOKEN_FAILED";
    ErrorCode[ErrorCode["NFT_TRANSFER_FAILED"] = 1019] = "NFT_TRANSFER_FAILED";
    ErrorCode[ErrorCode["DPLAT_TRANSFER_FAILED"] = 1020] = "DPLAT_TRANSFER_FAILED";
    ErrorCode[ErrorCode["FAILED_TO_FETCH_NFTS"] = 1021] = "FAILED_TO_FETCH_NFTS";
    ErrorCode[ErrorCode["FAILED_TO_FETCH_NFT_DETAIL"] = 1022] = "FAILED_TO_FETCH_NFT_DETAIL";
    ErrorCode[ErrorCode["FAILED_TO_FETCH_NFT_OWNERSHIP_HISTORY"] = 1023] = "FAILED_TO_FETCH_NFT_OWNERSHIP_HISTORY";
    ErrorCode[ErrorCode["FAILED_TO_GET_NFT_COUNT"] = 1024] = "FAILED_TO_GET_NFT_COUNT";
    ErrorCode[ErrorCode["FAILED_TO_ADD_USER_NFT_DETAIL"] = 1025] = "FAILED_TO_ADD_USER_NFT_DETAIL";
    ErrorCode[ErrorCode["FAILED_TO_GET_USER_NFT_DETAIL"] = 1026] = "FAILED_TO_GET_USER_NFT_DETAIL";
    ErrorCode[ErrorCode["FAILED_TO_ADD_NFT_BOOKMARK"] = 1027] = "FAILED_TO_ADD_NFT_BOOKMARK";
    ErrorCode[ErrorCode["FAILED_TO_DELETE_NFT_BOOKMARK"] = 1028] = "FAILED_TO_DELETE_NFT_BOOKMARK";
    ErrorCode[ErrorCode["FAILED_TO_GET_ADDRESS_BY_EMAIL"] = 1029] = "FAILED_TO_GET_ADDRESS_BY_EMAIL";
    ErrorCode[ErrorCode["FAILED_TO_GET_CONTRACT_DETAIL"] = 1030] = "FAILED_TO_GET_CONTRACT_DETAIL";
    ErrorCode[ErrorCode["WALLET_GET_TOKEN_BALANCE_FAILED"] = 1031] = "WALLET_GET_TOKEN_BALANCE_FAILED";
    ErrorCode[ErrorCode["FAILED_TO_CREATE_RELAY_UNSIGNED_TXN"] = 1032] = "FAILED_TO_CREATE_RELAY_UNSIGNED_TXN";
    ErrorCode[ErrorCode["FAILED_TO_CREATE_RELAY_DPLAT_TRANSFER_TXN"] = 1033] = "FAILED_TO_CREATE_RELAY_DPLAT_TRANSFER_TXN";
    ErrorCode[ErrorCode["FAILED_TO_SEND_REQUEST_TO_RELAY"] = 1034] = "FAILED_TO_SEND_REQUEST_TO_RELAY";
    ErrorCode[ErrorCode["ESTIMATE_DPLAT_TRANSFER_GAS_FAILED"] = 1035] = "ESTIMATE_DPLAT_TRANSFER_GAS_FAILED";
    ErrorCode[ErrorCode["ESTIMATE_NFT_TRANSFER_GAS_FAILED"] = 1036] = "ESTIMATE_NFT_TRANSFER_GAS_FAILED";
    ErrorCode[ErrorCode["NETWORK_TYPE_MISMATCHED"] = 1037] = "NETWORK_TYPE_MISMATCHED";
    ErrorCode[ErrorCode["FAILED_TO_GET_PRICE"] = 1038] = "FAILED_TO_GET_PRICE";
})(ErrorCode || (exports.ErrorCode = ErrorCode = {}));
var ErrorCategory;
(function (ErrorCategory) {
    ErrorCategory["WALLET_CORE"] = "Wallet-Core";
    ErrorCategory["DEFAULT"] = "Default";
    ErrorCategory["WEB3AUTH"] = "Web3auth-Internal";
    ErrorCategory["RELAY_SERVER"] = "Relay-Server";
    ErrorCategory["NFT_API"] = "NFT-Event-Service";
    ErrorCategory["CONFIG_API"] = "Config-Service";
    ErrorCategory["PRICE_API"] = "Token-Price-Service";
})(ErrorCategory || (exports.ErrorCategory = ErrorCategory = {}));
exports.errorMessageMap = {
    [ErrorCode.WALLET_NOT_CONNECTED]: "Failed to connect the wallet.",
    [ErrorCode.WALLET_SET_NETWORK_FAILED]: "Failed to configure the blockchain network.",
    [ErrorCode.SIGNATURE_FAILED]: "Failed to sign the transaction.",
    [ErrorCode.GET_KEY_PROVIDER_FAILED]: "Failed to fetch the key provider.",
    [ErrorCode.NOT_IMPLEMENTED]: "Method not implemented.",
    [ErrorCode.NOT_INITIALIZED]: "Wallet not initialized.",
    [ErrorCode.TRANSACTION_NOT_FOUND]: "Transaction not found.",
    [ErrorCode.INSUFFICIENT_BALANCE]: "Insufficient balance in the wallet.",
    [ErrorCode.TRIGGER_LOGIN_ERROR]: "Failed to log-in to the web3auth network.",
    [ErrorCode.FAILED_GENERATION_PASSPHRASE]: "Failed to generate a recovery passphrase on the server.",
    [ErrorCode.FAILED_TO_FETCH_PASSPHRASE]: "Failed to fetch the passphrase from the server.",
    [ErrorCode.FAILED_TO_UPDATE_PASSPHRASE]: "Failed to update the passphrase on the server.",
    [ErrorCode.INCORRECT_PASSPHRASE]: "Invalid passphrase provided.",
    [ErrorCode.FAILED_TO_FETCH_TOKENS]: "Failed to receive DPLAT tokens from the faucet.",
    [ErrorCode.FAILED_TO_FETCH_TRANSACTIONS]: "Failed to fetch the transaction history.",
    [ErrorCode.INVALID_PRIVATE_KEY]: "Failed to create a user account due to an invalid private key.",
    [ErrorCode.TOO_MANY_FETCH_TOKEN_REQUEST]: "Too many token requests from the faucet. Please try again after 5 minutes.",
    [ErrorCode.ESTIMATE_TOKEN_FAILED]: "Failed to estimate the required DPLAT tokens for the operation.",
    [ErrorCode.NFT_TRANSFER_FAILED]: "Failed to transfer the NFT.",
    [ErrorCode.DPLAT_TRANSFER_FAILED]: "Failed to transfer DPLAT tokens.",
    [ErrorCode.FAILED_TO_FETCH_NFTS]: "Failed to fetch the NFTs.",
    [ErrorCode.FAILED_TO_FETCH_NFT_DETAIL]: "Failed to fetch the NFT metadata details.",
    [ErrorCode.FAILED_TO_FETCH_NFT_OWNERSHIP_HISTORY]: "Failed to fetch the NFT ownership history.",
    [ErrorCode.FAILED_TO_GET_NFT_COUNT]: "Failed to get the count of NFTs.",
    [ErrorCode.FAILED_TO_ADD_USER_NFT_DETAIL]: "Failed to add the user's NFT details.",
    [ErrorCode.FAILED_TO_GET_USER_NFT_DETAIL]: "Failed to get the user's NFT details.",
    [ErrorCode.FAILED_TO_ADD_NFT_BOOKMARK]: "Failed to add the NFT bookmark.",
    [ErrorCode.FAILED_TO_DELETE_NFT_BOOKMARK]: "Failed to delete the NFT bookmark.",
    [ErrorCode.FAILED_TO_GET_ADDRESS_BY_EMAIL]: "Failed to retrieve the address associated with the email.",
    [ErrorCode.FAILED_TO_GET_CONTRACT_DETAIL]: "Failed to retrieve the smart contract details.",
    [ErrorCode.WALLET_GET_TOKEN_BALANCE_FAILED]: "Failed to fetch the balance of DPLAT tokens.",
    [ErrorCode.FAILED_TO_CREATE_RELAY_UNSIGNED_TXN]: "Failed to create an unsigned transaction for relay server.",
    [ErrorCode.FAILED_TO_CREATE_RELAY_DPLAT_TRANSFER_TXN]: "Failed to create a transaction for DPLAT transfer in relay server.",
    [ErrorCode.FAILED_TO_SEND_REQUEST_TO_RELAY]: "Failed to send the transaction to the relay server.",
    [ErrorCode.ESTIMATE_DPLAT_TRANSFER_GAS_FAILED]: "Failed to estimate the required gas for DPLAT transfer.",
    [ErrorCode.ESTIMATE_NFT_TRANSFER_GAS_FAILED]: "Failed to estimate the required gas for NFT transfer.",
    [ErrorCode.NETWORK_TYPE_MISMATCHED]: "Network-type (Mainnet ot Testnet) mismatched, please check the selected network type",
    [ErrorCode.FAILED_TO_GET_PRICE]: "Failed to get the token price",
};
exports.errorCategoryMap = {
    [ErrorCode.WALLET_NOT_CONNECTED]: ErrorCategory.WALLET_CORE,
    [ErrorCode.WALLET_SET_NETWORK_FAILED]: ErrorCategory.WALLET_CORE,
    [ErrorCode.SIGNATURE_FAILED]: ErrorCategory.WALLET_CORE,
    [ErrorCode.GET_KEY_PROVIDER_FAILED]: ErrorCategory.WEB3AUTH,
    [ErrorCode.NOT_IMPLEMENTED]: ErrorCategory.DEFAULT,
    [ErrorCode.NOT_INITIALIZED]: ErrorCategory.WALLET_CORE,
    [ErrorCode.TRANSACTION_NOT_FOUND]: ErrorCategory.WALLET_CORE,
    [ErrorCode.INSUFFICIENT_BALANCE]: ErrorCategory.WALLET_CORE,
    [ErrorCode.TRIGGER_LOGIN_ERROR]: ErrorCategory.WEB3AUTH,
    [ErrorCode.FAILED_GENERATION_PASSPHRASE]: ErrorCategory.CONFIG_API,
    [ErrorCode.FAILED_TO_FETCH_PASSPHRASE]: ErrorCategory.CONFIG_API,
    [ErrorCode.FAILED_TO_UPDATE_PASSPHRASE]: ErrorCategory.CONFIG_API,
    [ErrorCode.INCORRECT_PASSPHRASE]: ErrorCategory.WEB3AUTH,
    [ErrorCode.FAILED_TO_FETCH_TOKENS]: ErrorCategory.RELAY_SERVER,
    [ErrorCode.FAILED_TO_FETCH_TRANSACTIONS]: ErrorCategory.RELAY_SERVER,
    [ErrorCode.INVALID_PRIVATE_KEY]: ErrorCategory.WEB3AUTH,
    [ErrorCode.TOO_MANY_FETCH_TOKEN_REQUEST]: ErrorCategory.RELAY_SERVER,
    [ErrorCode.ESTIMATE_TOKEN_FAILED]: ErrorCategory.RELAY_SERVER,
    [ErrorCode.NFT_TRANSFER_FAILED]: ErrorCategory.WALLET_CORE,
    [ErrorCode.DPLAT_TRANSFER_FAILED]: ErrorCategory.WALLET_CORE,
    [ErrorCode.FAILED_TO_FETCH_NFTS]: ErrorCategory.NFT_API,
    [ErrorCode.FAILED_TO_FETCH_NFT_DETAIL]: ErrorCategory.NFT_API,
    [ErrorCode.FAILED_TO_FETCH_NFT_OWNERSHIP_HISTORY]: ErrorCategory.NFT_API,
    [ErrorCode.FAILED_TO_GET_NFT_COUNT]: ErrorCategory.NFT_API,
    [ErrorCode.FAILED_TO_ADD_USER_NFT_DETAIL]: ErrorCategory.NFT_API,
    [ErrorCode.FAILED_TO_GET_USER_NFT_DETAIL]: ErrorCategory.NFT_API,
    [ErrorCode.FAILED_TO_ADD_NFT_BOOKMARK]: ErrorCategory.CONFIG_API,
    [ErrorCode.FAILED_TO_DELETE_NFT_BOOKMARK]: ErrorCategory.CONFIG_API,
    [ErrorCode.FAILED_TO_GET_ADDRESS_BY_EMAIL]: ErrorCategory.CONFIG_API,
    [ErrorCode.FAILED_TO_GET_CONTRACT_DETAIL]: ErrorCategory.RELAY_SERVER,
    [ErrorCode.WALLET_GET_TOKEN_BALANCE_FAILED]: ErrorCategory.WALLET_CORE,
    [ErrorCode.FAILED_TO_CREATE_RELAY_UNSIGNED_TXN]: ErrorCategory.RELAY_SERVER,
    [ErrorCode.FAILED_TO_CREATE_RELAY_DPLAT_TRANSFER_TXN]: ErrorCategory.RELAY_SERVER,
    [ErrorCode.FAILED_TO_SEND_REQUEST_TO_RELAY]: ErrorCategory.RELAY_SERVER,
    [ErrorCode.ESTIMATE_DPLAT_TRANSFER_GAS_FAILED]: ErrorCategory.RELAY_SERVER,
    [ErrorCode.ESTIMATE_NFT_TRANSFER_GAS_FAILED]: ErrorCategory.RELAY_SERVER,
    [ErrorCode.NETWORK_TYPE_MISMATCHED]: ErrorCategory.WALLET_CORE,
    [ErrorCode.FAILED_TO_GET_PRICE]: ErrorCategory.PRICE_API,
};
