"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertTxnResponseToITransactions = exports.convertAPITxnToTransaction = exports.getTxnType = void 0;
const zbyte_common_1 = require("@zbyteio/zbyte-common");
const common_1 = require("../common");
const ethers_1 = require("ethers");
function getTxnType(operation) {
    switch (operation) {
        case zbyte_common_1.RelayOperations.DEPLOY:
            return "Deploy contract";
        case zbyte_common_1.RelayOperations.INVOKE:
            return "Smart-contract interaction";
        case zbyte_common_1.RelayOperations.TRANSFER:
            return "DPLAT Transfer";
        case zbyte_common_1.RelayOperations.NFT_TRANSFER:
            return "NFT Transfer";
        default:
            throw Error("Invalid operation type");
    }
}
exports.getTxnType = getTxnType;
function convertAPITxnToTransaction(txn) {
    const convertedTxn = {
        name: txn.operation,
        status: txn.status,
        testnet: (0, zbyte_common_1.getBlockchainNetwork)(txn.chainId).networkType,
        timestamp: new Date(txn.timestamp),
        txnHash: txn.txnHash,
        txnExplorerUrl: `${(0, zbyte_common_1.getBlockchainNetwork)(txn.chainId).explorer}tx/${txn.txnHash}`,
        txnChainSymbol: common_1.TxnChain[(0, zbyte_common_1.getBlockchainNetwork)(txn.chainId)?.chainSymbol] || "",
    };
    if (txn?.status === "failure") {
        convertedTxn.error = txn.error || "";
    }
    return convertedTxn;
}
exports.convertAPITxnToTransaction = convertAPITxnToTransaction;
function convertTxnResponseToITransactions(txnResponseList) {
    const listItxns = [];
    for (const txnResponse of txnResponseList) {
        const itxn = {
            dplatToken: "",
            type: "Deploy contract",
            timeStamp: new Date(0),
            mainTxn: {
                name: "",
                status: "",
                testnet: "Testnet",
                timestamp: new Date(0),
                txnHash: "",
                txnExplorerUrl: "",
                txnChainSymbol: "",
            },
            subTxns: [],
        };
        const txnDetailList = Object.values(txnResponse)[0];
        txnDetailList.forEach((txnDetail) => {
            try {
                if (txnDetail.zGas !== "" && txnDetail.zGas !== undefined) {
                    itxn.dplatToken = ethers_1.ethers.utils.formatEther(ethers_1.ethers.BigNumber.from(txnDetail.zGas));
                }
            }
            catch (error) {
                console.error("failed to convert string to bignumber for gas value", txnDetail.zGas);
                itxn.dplatToken = txnDetail.zGas;
            }
            let functionName;
            let type;
            if (txnDetail.metadata !== undefined) {
                const metadata = JSON.parse(txnDetail.metadata);
                functionName = metadata.functionName;
                itxn.metadata = metadata;
                type = getTxnType(metadata.operation);
            }
            txnDetail.transactions.forEach((txn) => {
                if (txn.operation === "deployContract") {
                    itxn.type = "Deploy contract";
                    itxn.timeStamp = new Date(txn.timestamp);
                    itxn.mainTxn = convertAPITxnToTransaction(txn);
                }
                else if (txn.operation === "user" ||
                    (type == "DPLAT Transfer" && txn.operation === "transferDPLAT")) {
                    itxn.type = type || "Smart-contract interaction";
                    itxn.timeStamp = new Date(txn.timestamp);
                    itxn.mainTxn = convertAPITxnToTransaction(txn);
                    itxn.mainTxn.name = functionName || "smartContractInvocation";
                }
                else {
                    itxn.subTxns?.push(convertAPITxnToTransaction(txn));
                }
            });
        });
        // Only if mainTransaction have txnHash then only
        // it will shown on transaction history UI
        if (itxn.mainTxn.txnHash !== "") {
            listItxns.push(itxn);
        }
    }
    return listItxns;
}
exports.convertTxnResponseToITransactions = convertTxnResponseToITransactions;
