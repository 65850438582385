import React, { FC } from "react";
import "./style.scss";
import { Button } from "antd";
import { Link } from "react-router-dom";

type IProps = {
  imageUrl: string;
  itemName: string;
  isButton?: boolean;
  itemCreator?: string;
  itemPrice?: string;
  itemQuantity?: string;
  unitsAvailableForConsumption?: string;
  itemId: string;
  creatorId: string;
};

const ProductCard: FC<IProps> = ({
  imageUrl,
  isButton = true,
  itemName,
  itemCreator,
  itemPrice,
  itemQuantity,
  unitsAvailableForConsumption,
  itemId,
  creatorId,
}) => {
  console.log(itemId);

  const renderButton = () => {
    if (!isButton) {
      return null;
    }

    return (
      <div className='button-container' style={{ height: 16 }}>
        <Link
          to={`/checkout/${encodeURIComponent(itemId)}/${encodeURIComponent(
            itemCreator || ""
          )}/${encodeURIComponent(creatorId || "")}/${encodeURIComponent(
            itemName
          )}/${encodeURIComponent(itemPrice || "0")}/${encodeURIComponent(
            itemQuantity || "0"
          )}`}
        >
          <Button type='primary' block>
            Sponsor
          </Button>
        </Link>
      </div>
    );
  };

  return (
    <div
      className={`category-card-item`}
      style={{ height: 600, marginTop: -110 }}
    >
      <div className='card-image'>
        <img src={imageUrl} alt='' />
      </div>
      <div className='card-title'>{itemName}</div>
      <div
        className='card-info'
        style={{ height: 180, background: "transparent" }}
      >
        <div className='info-item'>
          <div className='info-title'>CREATOR</div>
          <div className='info-desc'>{itemCreator}</div>
        </div>
        <div className='info-item'>
          <div className='info-title'>PRICE</div>
          <div className='info-desc'>{itemPrice}</div>
        </div>
        {itemQuantity && (
          <div className='info-item'>
            <div className='info-title'>TOTAL QUANTITY</div>
            <div className='info-desc'>{itemQuantity}</div>
          </div>
        )}
        {itemQuantity && (
          <div className='info-item'>
            <div className='info-title'>DISTRIBUTED</div>
            <div className='info-desc'>{unitsAvailableForConsumption}</div>
          </div>
        )}
      </div>
      {renderButton()}
    </div>
  );
};

export default ProductCard;
