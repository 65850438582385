import React, { useContext, useEffect, useState } from "react";
import { TORUS_NETWORK_TYPE, WalletCore } from "@zbyteio/zbyte-wallet-sdk-core";
import {
  NetworkConfig,
  getBlockchainNetwork,
  Web3AuthLoginParams,
} from "@zbyteio/zbyte-common";
import { Web3AuthProvider } from "@zbyteio/zbyte-wallet-sdk-core";
import { KeycloakContext } from "../../context/keycloak";
import { MockZbyteWallet } from "../../mockWallet";
import { sha256 } from "hash.js";
import { RelayClient } from "@zbyteio/zbyte-relay-client";
import { Triangle } from "react-loader-spinner";
import { contractAbi, contractAddress } from "../../constants";
import axios from "axios";
import { db } from "../../firebase/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import QRCode from "react-qr-code";
import CryptoJS from "crypto-js";


const config = {
  networkType: process.env.REACT_APP_AUTH_NETWORK_TYPE as TORUS_NETWORK_TYPE,
  web3AuthClientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
  enableLogging: (process.env.REACT_APP_ENABLE_LOGGING as string) === "true",
  verifierName: process.env.REACT_APP_VERIFIER as string,
  clientId: process.env.REACT_APP_CLIENT_ID as string,
  domain: process.env.REACT_APP_DOMAIN as string,
};
const web3Auth = new Web3AuthProvider(config);
const walletProvider: IWalletProvider = web3Auth;
const walletCore = new WalletCore(walletProvider, getBlockchainNetwork(137));
window.apiBaseUrl = "https://dplat.zbyte.io";

export default function TGA() {
  const urlParams = new URLSearchParams(window.location.search);
  const itemId = urlParams.get("itemId");
  if (itemId !== "") {
    localStorage.setItem("itemId", itemId || "");
  }
  // Add ABI here
  const abiAirdrop = contractAbi;

  const auth = useContext(KeycloakContext);

  const [walletLoading, setWalletLoading] = useState(true);
  const [isVerifying, setIsVerifying] = useState(false); // New state for minting status
  localStorage.setItem("redirectURL", "https://ugcdemo.com");

  const [popupOpened, setPopupOpened] = useState(false);
  const [qrCodeData, setQrCodeData] = useState<string | null>(null);

  let email = '';
  const handleLogout = () => auth?.logoutSession();
  const handleRefresh = async () => await auth?.refreshSession(-1);

  useEffect(() => {
    // Connect wallet after 4 seconds
    const connectWalletTimeout = setTimeout(async () => {
      try {
        await connectWallet();
        try {
          await checkOwner();
        } catch (err) {
          console.log(err);
          await window.opener.postMessage("Access denied", "*");
          setIsVerifying(false);
          window.close();
        }
      } catch (err) {
        console.log(err);
        localStorage.setItem("account", "");
        await handleLogout();
        openLoginPopup();
      }
    }, 2000);

    // Clear timeout to prevent memory leaks
    return () => clearTimeout(connectWalletTimeout);
  }, []);

  useEffect(() => {
    if (popupOpened) {
      const handleAccountChange = () => {
        //Need to fix this as a listener
        if (localStorage.getItem("account")) {
          // Refresh the page if "account" is populated
          window.location.reload();
        }
      };

      window.addEventListener("storage", handleAccountChange);

      // Cleanup function to remove event listener
      return () => {
        window.removeEventListener("storage", handleAccountChange);
      };
    }
  }, [popupOpened]); // Add popupOpened as dependency

  async function connectWallet() {
    const info = JSON.parse(localStorage.getItem("account") as string);
    email = info.email;
    const loginParams: Web3AuthLoginParams = {
      clientId: process.env.REACT_APP_CLIENT_ID as string,
      domain: process.env.REACT_APP_DOMAIN as string,
      tokenExpiry: Number(process.env.REACT_APP_TOKEN_EXPIRY || ""),
      typeOfToken: process.env.REACT_APP_TYPE_OF_TOKEN,
      verifier: process.env.REACT_APP_VERIFIER as string,
      accessToken: info.token,
    };

    if (!walletCore.isConnected()) {
      console.log("WalletCore isn't connected");
      walletCore.injectAuthVerifier(loginParams);
      await walletCore.connect();

      const address = await walletCore.getAddress();

      console.log(address);
      console.log("Wallet connected");
      setWalletLoading(false); // Update loading state once wallet is connected
    }
  }

  async function logout() {
    auth?.logoutSession();
  }

  // Function to open the login popup
  const openLoginPopup = () => {
    setPopupOpened(true);
    const loginPopup = window.open(
      "/tgalogin",
      "LoginPopup",
      "width=600,height=400"
    );
    if (!loginPopup) {
      console.error("Failed to open login popup window.");
    }
  };

  async function getRobloxId(accountEmail: string) {
    try {
      const q = query(
        collection(db, "users"),
        where("email", "==", accountEmail)
      );

      const querySnapshot = await getDocs(q);

      let robloxId = null;

      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          robloxId = doc.data().robloxUserId;
        }
      });

      if (robloxId) {
        console.log("Roblox ID:", robloxId);
      } else {
        console.log("No matching document found");
      }

      return robloxId;
    } catch (error) {
      console.error("Error fetching document: ", error);
    }
  }

  async function checkOwner() {
    setIsVerifying(true);

    const mockWallet = new MockZbyteWallet(walletCore);
    const walletAddress = await walletCore.getAddress();

    const relayConfig = {
      relayBaseURL: "https://dplat.zbyte.io/relay/v1", // URL for the relay service
      nativeChainId: 137, // Chain ID for the native blockchain (137 for mainnet, 80001 for testnet)
      pollWait: 5000, // Wait time between subsequent polling calls for operation status
      pollTimeOut: 300000, // Timeout duration for ceasing waiting on an operation
    };

    const relayClient = new RelayClient(relayConfig, mockWallet);

    try {
      const isOwner = await relayClient.invokeContract(
        "hasNFTWithUniqueId",
        contractAddress,
        contractAbi,
        [walletAddress, itemId],
        137
      );

      const robloxUserId = await getRobloxId(email);

      // Encrypt the QRString
      const QRString = walletAddress + '/' + itemId + '/' + robloxUserId;

      const encryptionKey = process.env.REACT_APP_TGA_ENCRYPTION_KEY;
      if (!encryptionKey) {
        throw new Error("Encryption key is not defined");
      }

      if (!isOwner) {
        await window.opener.postMessage("Access denied", "*");
        setIsVerifying(false);
        window.close();
      } else {

        const encrypted = CryptoJS.AES.encrypt(QRString, encryptionKey).toString();

        setQrCodeData(encrypted);
        await window.opener.postMessage("Access granted", "*");
        setIsVerifying(false);
      }
    } catch (error) {
      console.error("Error checking NFT:", error);
      // Handle error here
    }
  }

  return (
    <main
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      {(walletLoading || isVerifying) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src='https://i.ibb.co/pvV46fC/8P9P.gif' alt='Loading...' />
          <p>
            {walletLoading
              ? "Verifying eligibility..."
              : "Verifying eligibility..."}
          </p>
        </div>
      )}
      {qrCodeData && (
        <div>
          <QRCode value={qrCodeData} />
          <p>Scan this QR code</p>
        </div>
      )}
    </main>
  );
}

interface IWalletProvider {
  connect(): Promise<any>;
  isConnected(): boolean;
  getKeyProvider(networkConfig: NetworkConfig): Promise<IKeyProvider>;
}

interface IKeyProvider {
  readonly serviceProviderName: string;
  addChain(networkConfig: NetworkConfig): Promise<void>;
  switchChain(chainId: string): Promise<void>;
  getProvider(): any;
}
