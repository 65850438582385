import React, { useContext, useEffect, useState } from "react";
import { db } from "../../firebase/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { KeycloakContext } from "../../context/keycloak";
import { TORUS_NETWORK_TYPE, WalletCore } from "@zbyteio/zbyte-wallet-sdk-core";
import { NetworkConfig, getBlockchainNetwork, Web3AuthLoginParams } from "@zbyteio/zbyte-common";
import { Web3AuthProvider } from "@zbyteio/zbyte-wallet-sdk-core";
import { RelayClient } from "@zbyteio/zbyte-relay-client";
import { MockZbyteWallet } from "../../mockWallet";
import { contractAbi, contractAddress } from "../../constants";
import axios from "axios";
import { Triangle } from "react-loader-spinner";

const config = {
  networkType: process.env.REACT_APP_AUTH_NETWORK_TYPE as TORUS_NETWORK_TYPE,
  web3AuthClientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
  enableLogging: (process.env.REACT_APP_ENABLE_LOGGING as string) === "true",
  verifierName: process.env.REACT_APP_VERIFIER as string,
  clientId: process.env.REACT_APP_CLIENT_ID as string,
  domain: process.env.REACT_APP_DOMAIN as string,
}

const web3Auth = new Web3AuthProvider(config);
const walletProvider: IWalletProvider = web3Auth;
const walletCore = new WalletCore(walletProvider, getBlockchainNetwork(137));
window.apiBaseUrl = "https://dplat.zbyte.io";

interface Sponsorship {
  id: string; // Document ID
  description: string;
  irlRewardDescription: string;
  itemCreator: string;
  itemName: string;
  payment: string;
  quantity: string;
  sponsor: string;
  status: string;
  yourPrice: string;
}

interface Props {
  sponsorship: Sponsorship;
}

function ListItem({ sponsorship }: Props) {


  const auth = useContext(KeycloakContext);
  const handleRefresh = async () => await auth?.refreshSession(-1);
  const handleLogout = () => auth?.logoutSession();
  const [walletLoading, setWalletLoading] = useState(true);
  const [approvalLoading, setApprovalLoading] = useState(false);

  useEffect(() => {
    // Connect wallet after 4 seconds
    /*
    const connectWalletTimeout = setTimeout(async () => {
      try {
       setWalletLoading(false);
      }
      catch (err) {
        localStorage.setItem("account", "");
        handleLogout();
        window.location.href = '/';
      }
    }, 4000);

    // Clear timeout to prevent memory leaks
    return () => clearTimeout(connectWalletTimeout);
    */
   setWalletLoading(false);
  }, []);

  async function connectWallet() {
    const info = JSON.parse(localStorage.getItem("account") as string);
    console.log(info);
    const loginParams: Web3AuthLoginParams = {
      clientId: process.env.REACT_APP_CLIENT_ID as string,
      domain: process.env.REACT_APP_DOMAIN as string,
      tokenExpiry: Number(process.env.REACT_APP_TOKEN_EXPIRY || ""),
      typeOfToken: process.env.REACT_APP_TYPE_OF_TOKEN,
      verifier: process.env.REACT_APP_VERIFIER as string,
      accessToken: info.token,
    };

    if (!walletCore.isConnected()) {
      console.log("WalletCore isn't connected");
      walletCore.injectAuthVerifier(loginParams);
      await walletCore.connect();

      console.log(await walletCore.getAddress());
      console.log("Wallet connected");
      setWalletLoading(false); // Update loading state once wallet is connected

    }
  }

  const mintItem = async () => {

    const itemURL = await getIPFS(sponsorship.id, sponsorship.itemName, sponsorship.description);
    console.log(itemURL);

    const mockWallet = new MockZbyteWallet(walletCore);

    const walletAddress = await mockWallet.getAddress();

    const relayConfig = {
      relayBaseURL: "https://dplat.zbyte.io/relay/v1",
      nativeChainId: 137,
      pollWait: 5000,
      pollTimeOut: 300000,
    };

    const relayClient = new RelayClient(relayConfig, mockWallet);

    const functionSignature = "safeMint";
    const contractHash = contractAddress;
    const abi = contractAbi;
    const args = [itemURL, sponsorship.quantity, sponsorship.id];
    const chainId = 137;

    const result = await relayClient.invokeContract(functionSignature, contractHash, abi, args, chainId);
    console.log(result);

  }

  const handleApprove = async () => {
    setApprovalLoading(true);
    const sponsorName = await getCompanyName(sponsorship.sponsor);
    try {
      //await mintItem();
      const sponsorshipDocRef = doc(db, "Sponsorships", sponsorship.id);
      await updateDoc(sponsorshipDocRef, {
        status: "Approved",
        sponsorName: sponsorName
      });
      console.log("Sponsorship status updated to 'Approved'");
      setApprovalLoading(false);
    } catch (error) {
      console.error("Error updating sponsorship status:", error);
    }
  };

  // Function to fetch username based on user ID
  async function getIPFS(itemId: string, itemName: string, itemDescription: string) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(`https://ugc-verse-backend.vercel.app/uploadItemImageToIPFS/${itemId}/${itemName}/${itemDescription}`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching username:', error);
    }
  }

  // Function to retrieve companyName for a specific user's sponsorship document
  async function getCompanyName(userEmail: string) {
    try {
      const userDocRef = doc(db, "users", userEmail);
      const userDocSnapshot = await getDoc(userDocRef);

      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        console.log(userData);
        if (userData && userData.companyName) {
          return userData.companyName;
        } else {
          return "Company name not found for the user's sponsorship.";
        }
      } else {
        return "User document not found.";
      }
    } catch (error) {
      console.error("Error retrieving companyName:", error);
      return "Error retrieving companyName.";
    }
  }


  return (
    <>
      {!walletLoading && (
        <tr className='sponsorship-item'>
          <td style={{ borderRight: "1px solid #ccc", padding: "8px" }}>{sponsorship.id}</td> {/* Document ID */}
          <td style={{ borderRight: "1px solid #ccc", padding: "8px" }}>
            <div>
              <div>Creator: {sponsorship.itemCreator}</div>
              <div>Item Name: {sponsorship.itemName}</div>
              <div>Payment status: {sponsorship.payment}</div>
              <div>Sponsor: {sponsorship.sponsor}</div>
              <div style={{ fontWeight: "bold" }}>Approval Status: {sponsorship.status}</div>
              <div>Quantity: {sponsorship.quantity}</div>
              <div>Description: {sponsorship.description}</div>
              <div>Reward: {sponsorship.irlRewardDescription}</div>
              <div>Price: {sponsorship.yourPrice}</div>
            </div>
          </td>
          <td style={{ padding: "8px" }}>
            {approvalLoading ? (
              <Triangle color="#333" height={20} width={20} />
            ) : (
              sponsorship.status === "Pending" && (
                <button onClick={handleApprove}>Approve</button>
              )
            )}
          </td>
        </tr>
      )}

      {(walletLoading || approvalLoading) && (
        <tr>
          <Triangle color="#4fa94d" height={80} width={80} />
        </tr>
      )}
    </>
  );
}

interface IWalletProvider {
  connect(): Promise<any>;
  isConnected(): boolean;
  getKeyProvider(networkConfig: NetworkConfig): Promise<IKeyProvider>;
}

interface IKeyProvider {
  readonly serviceProviderName: string;
  addChain(networkConfig: NetworkConfig): Promise<void>;
  switchChain(chainId: string): Promise<void>;
  getProvider(): any;
}

export default ListItem;
