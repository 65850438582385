"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFunctionABIFragment = exports.createUnsignedWalletTransaction = exports.extractFunctionSignature = exports.extractFunctionArgTypes = void 0;
const ethers_1 = require("ethers");
const relay_client_1 = require("../lib/relay.client");
function extractFunctionArgTypes(functionFragment, args) {
    const argsInfo = [];
    if (functionFragment.inputs.length != args.length) {
        throw new Error("invalid number of arguments passed to function" + functionFragment.name);
    }
    functionFragment.inputs.forEach((param, index) => {
        argsInfo.push({
            name: param.name,
            type: param.baseType,
            value: args[index],
        });
    });
    return argsInfo;
}
exports.extractFunctionArgTypes = extractFunctionArgTypes;
function extractFunctionSignature(functionAbi, encodedData) {
    const functionArgs = [];
    const functionInterface = new ethers_1.ethers.utils.Interface(functionAbi);
    const functionName = functionInterface.fragments[0].name;
    const functionData = functionInterface.decodeFunctionData(functionInterface.fragments[0].name, encodedData);
    for (const param of functionInterface.fragments[0].inputs) {
        functionArgs.push({
            name: param.name,
            type: param.type,
            value: functionData[param.name].toString(),
        });
    }
    console.log("args for function:", functionInterface.fragments[0].name, "is:", functionArgs);
    return {
        name: functionName,
        args: functionArgs,
    };
}
exports.extractFunctionSignature = extractFunctionSignature;
function createUnsignedWalletTransaction(transactionMetadata, operation, functionName, contractHash, args) {
    return {
        data: transactionMetadata,
        metadata: {
            functionName: functionName || "",
            contractHash: contractHash || "",
            args: args || [],
            subOperation: operation,
        },
    };
}
exports.createUnsignedWalletTransaction = createUnsignedWalletTransaction;
function getFunctionABIFragment(functionSignature, contractHash, abi, args, chainId, fromAddress, value = "0") {
    const targetContractInfo = new relay_client_1.ContractInfo(contractHash, abi, chainId);
    if (targetContractInfo.isConstantFunction(functionSignature)) {
        if (value !== "0") {
            console.warn("DO NOT PASS VALUE TO A CONSTANT FUNCTION CALL");
        }
        return targetContractInfo.callConstantFunction(functionSignature, args, fromAddress);
    }
    if (!targetContractInfo.isPayable(functionSignature) && value !== "0") {
        throw Error("cannot pass value to a non-payable function");
    }
    const targetInterface = new ethers_1.ethers.utils.Interface(abi);
    const functionFragment = targetInterface.getFunction(functionSignature);
    let functionABIFragment = functionFragment.format(ethers_1.ethers.utils.FormatTypes.json);
    functionABIFragment = "[" + functionABIFragment + "]";
    return functionABIFragment;
}
exports.getFunctionABIFragment = getFunctionABIFragment;
