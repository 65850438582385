import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import "../../styles/login.scss";
import SignUpForm from "./SignUpForm";
import { Button } from "antd";
import IAccount from "../../types/IAccount";
import { useNavigate } from "react-router-dom";

const SignUpPage = () => {
  const navigate = useNavigate();
  const localStorageAccount = JSON.parse(
    localStorage.getItem("account") || "{}"
  );
  const [account, setAccount] = useState<IAccount | any>({
    email: localStorageAccount?.email || "",
  });
  const [playerType, setPlayerType] = useState<string>(
    localStorage.getItem("playerType") || ""
  );

  useEffect(() => {
    const info = JSON.parse(localStorage.getItem("account") || "{}");
    if (!info?.token) {
      navigate("/");
      return;
    }
    setPlayerType(localStorage.getItem("playerType") || "");
  }, []);

  const handleNext = (accountValue: any): void => {
    setAccount({ ...accountValue });
    // Handle next action based on the player type
    switch (playerType) {
      case "Sponsor":
        handleSponsorSignup(accountValue);
        break;
      case "Creator":
        handleCreatorSignup(accountValue);
        break;
      case "Player":
        handlePlayerSignup(accountValue);
        break;
      default:
        break;
    }
  };

  const handleSponsorSignup = (accountValue: any) => {
    // Handle Sponsor signup
    console.log("Sponsor Signup:", accountValue);
  };

  const handleCreatorSignup = (accountValue: any) => {
    // Handle Creator signup
    console.log("Creator Signup:", accountValue);
  };

  const handlePlayerSignup = (accountValue: any) => {
    // Handle Player signup
    console.log("Player Signup:", accountValue);
  };

  return (
    <div className='login-page-container sign-up-container'>
      <div className='logo'>
        <img src={logo} alt='' />
      </div>
      <div className='lg-title'>Sign Up</div>
      <SignUpForm onClickNext={handleNext} />
      <div className='form-item-content-btn'>
      </div>
    </div>
  );
};

export default SignUpPage;
