import React, { useState, useEffect, useContext } from "react";
import { db } from "../../firebase/firebase";
import { collection, getDocs, where, query, onSnapshot } from "firebase/firestore";
import ListItem from "./ListItem";
import { useNavigate } from "react-router-dom";


function SponsorshipListPage() {
  const [sponsorships, setSponsorships] = useState<any[]>([]);
  const [filter, setFilter] = useState("Pending"); // Default filter is Pending
  const navigate = useNavigate();


  useEffect(() => {
    const account = JSON.parse(localStorage.getItem("account") || "");
    if (account.email!=="liannguyen79@gmail.com" && account.email!=="saurabhrk@gmail.com")
      {
        navigate("/");
      }
    const unsubscribe = onSnapshot(collection(db, "Sponsorships"), (snapshot) => {
      const sponsorshipsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSponsorships(sponsorshipsData);
    });

    return () => unsubscribe();
  }, []); // Fetch sponsorships initially and subscribe to changes

  useEffect(() => {
    const fetchSponsorships = async () => {
      try {
        let q;
        if (filter === "All") {
          q = collection(db, "Sponsorships");
        } else {
          q = query(collection(db, "Sponsorships"), where("status", "==", filter));
        }
        const snapshot = await getDocs(q);
        const sponsorshipsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSponsorships(sponsorshipsData);
      } catch (error) {
        console.error("Error fetching sponsorships:", error);
      }
    };

    fetchSponsorships();
  }, [filter]); // Fetch sponsorships whenever the filter changes

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value);
  };

  return (
    <div className='main-area'>
      <div className='main-title-xl'>Sponsorships</div>
      <div className="filter-container">
        <label htmlFor="filter">Filter by Status:</label>
        <select id="filter" value={filter} onChange={handleFilterChange}>
          <option value="All">All</option>
          <option value="Pending">Pending</option>
          <option value="Approved">Approved</option>
        </select>
      </div>
      <table className='sponsorship-list'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Details</th>
            <th>Approve</th>
          </tr>
        </thead>
        <tbody>
          {sponsorships.map(sponsorship => (
            <ListItem key={sponsorship.id} sponsorship={sponsorship} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SponsorshipListPage;
