import React, { FC, useContext, useState } from "react";
import "./style.scss";
import { Button } from "antd";
import {
  TORUS_NETWORK_TYPE,
  WalletCore,
  Web3AuthProvider,
} from "@zbyteio/zbyte-wallet-sdk-core";
import { RelayClient } from "@zbyteio/zbyte-relay-client";
import {
  IWalletProvider,
  Web3AuthLoginParams,
  getBlockchainNetwork,
} from "@zbyteio/zbyte-common";
import { KeycloakContext } from "../../context/keycloak";
import { MockZbyteWallet } from "../../mockWallet";
import { contractAbi, contractAddress } from "../../constants";
import axios from "axios";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { Triangle } from "react-loader-spinner";
import { Mutex } from "async-mutex";

interface UserItem {
  assetType: number;
  creatorHasVerifiedBadge: boolean;
  creatorName: string;
  creatorTargetId: number;
  creatorType: string;
  description: string;
  favoriteCount: number;
  id: number;
  isOffSale: boolean;
  itemRestrictions: any[]; // Define the correct type if it's not any
  itemStatus: any[]; // Define the correct type if it's not any
  itemType: string;
  name: string;
  offSaleDeadline: any; // Define the correct type if it's not any
  priceStatus: string;
  productId: number;
  purchaseCount: number;
  saleLocationType: string;
}

type IProps = {
  imageUrl: string;
  itemName: string;
  isButton?: boolean;
  itemCreator?: string;
  itemPrice?: string;
  itemQuantity?: string;
  itemId: string;
  irlDescription: string;
  claimURL: string;
  description: string;
  claimed: boolean;
  itemSponsor?: string;
  closeModal: () => void;
};

const ClaimCard: FC<IProps> = ({
  imageUrl,
  isButton,
  itemName,
  itemCreator,
  itemPrice,
  itemQuantity,
  itemId,
  irlDescription,
  claimURL,
  description,
  claimed,
  itemSponsor,
  closeModal,
}) => {
  console.log(itemId);

  const config = {
    networkType: process.env.REACT_APP_AUTH_NETWORK_TYPE as TORUS_NETWORK_TYPE,
    web3AuthClientId: process.env.REACT_APP_AUTH_CLIENT_ID as string,
    enableLogging: (process.env.REACT_APP_ENABLE_LOGGING as string) === "true",
    verifierName: process.env.REACT_APP_VERIFIER as string,
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    domain: process.env.REACT_APP_DOMAIN as string,
  };

  const web3Auth = new Web3AuthProvider(config);
  const walletProvider: IWalletProvider = web3Auth;
  const walletCore = new WalletCore(walletProvider, getBlockchainNetwork(137));
  window.apiBaseUrl = "https://dplat.zbyte.io";
  const auth = useContext(KeycloakContext);
  const handleRefresh = async () => await auth?.refreshSession(-1);
  const handleLogout = () => auth?.logoutSession();

  const [isClaiming, setIsClaiming] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const mutex = new Mutex();

  async function connectWallet() {
    const info = JSON.parse(localStorage.getItem("account") as string);
    console.log(info);
    const loginParams: Web3AuthLoginParams = {
      clientId: process.env.REACT_APP_CLIENT_ID as string,
      domain: process.env.REACT_APP_DOMAIN as string,
      tokenExpiry: Number(process.env.REACT_APP_TOKEN_EXPIRY || ""),
      typeOfToken: process.env.REACT_APP_TYPE_OF_TOKEN,
      verifier: process.env.REACT_APP_VERIFIER as string,
      accessToken: info.token,
    };

    if (!walletCore.isConnected()) {
      console.log("WalletCore isn't connected");
      walletCore.injectAuthVerifier(loginParams);
      await walletCore.connect();

      console.log(await walletCore.getAddress());
      console.log("Wallet connected");
    }
  }

  const handleRedeem = async () => {
    window.location.href = "https://" + claimURL;
  };

  const handleClaim = async () => {
    setIsClaiming(true);

    // Connect wallet after 4 seconds
    setTimeout(async () => {
      try {
        await handleRefresh();
        await connectWallet();
        await claimNFT();
        setWalletConnected(true);
      } catch (err) {
        localStorage.setItem("account", "");
        handleLogout();
        window.location.href = "/";
      } finally {
        setIsClaiming(false);
      }
    }, 4000);
  };

  async function claimNFT() {
    await mutex.runExclusive(async () => {
      const address = await walletCore.getAddress();
      const ipfs = await getIPFS(itemId, itemName, description);
      console.log(ipfs);

      const relayConfig = {
        relayBaseURL: "https://dplat.zbyte.io/relay/v1", // URL for the relay service
        nativeChainId: 137, // Chain ID for the native blockchain (137 for mainnet, 80001 for testnet)
        pollWait: 5000, // Wait time between subsequent polling calls for operation status
        pollTimeOut: 300000, // Timeout duration for ceasing waiting on an operation
      };

      const mockWallet = new MockZbyteWallet(walletCore);
      const relayClient = new RelayClient(relayConfig, mockWallet);

      try {
        const response = await relayClient.invokeContract(
          "hasNFTWithUniqueId",
          contractAddress,
          contractAbi,
          [address, itemId],
          137
        );
        if (!response || response) {
          await relayClient.invokeContract(
            "safeMintTo",
            contractAddress,
            contractAbi,
            [address, ipfs, itemId],
            137
          );
          await uploadItemsToDB(itemId);
          window.location.reload();
        } else {
          console.log("NFT with this unique ID already exists.");
        }
      } catch (err) {
        console.log(err);
        console.log("Error minting NFT");
      }
    });
  }

  async function uploadItemsToDB(itemId: string) {
    try {
      const account = JSON.parse(localStorage.getItem("account") || "");
      const email = account.email;
      // Perform a Firestore query to find the user document based on the Roblox user ID
      const querySnapshot = await getDocs(
        query(collection(db, "users"), where("email", "==", email))
      );

      // Check if the query returned any documents
      if (!querySnapshot.empty) {
        // Get the first document (assuming there's only one user document per Roblox user)
        const userDoc = querySnapshot.docs[0];
        const userId = userDoc.id; // Get the user document ID

        // Get the current items array from the user document
        const userData = userDoc.data();
        const userItemsData = userData.items || [];

        // Update the item with the specified itemId
        const updatedItems = userItemsData.map(
          (item: { id: { toString: () => any } }) => {
            if (item.id.toString() === itemId.toString()) {
              // If the item id matches the specified itemId, set the claimed property to true
              return { ...item, claimed: true };
            }
            return item;
          }
        );

        // Update the user document with the updated items array
        await updateDoc(doc(db, "users", userId), {
          items: updatedItems,
          dataUpdated: new Date().getTime(), // Set the current time as the dataUpdated property
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Function to fetch username based on user ID
  async function getIPFS(
    itemId: string,
    itemName: string,
    itemDescription: string
  ) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/uploadItemImageToIPFS/${itemId}/${itemName}/${itemDescription}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  const renderButton = () => {
    if (!isButton) {
      return null;
    }

    if (claimed) {
      return (
        <Button type='primary' block onClick={handleRedeem}>
          Redeem
        </Button>
      );
    }

    return (
      <Button type='primary' block onClick={handleClaim}>
        Claim
      </Button>
    );
  };

  return (
    <div className={`category-card-item`} style={{ height: 490 }}>
      {isClaiming ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Triangle color='#00BFFF' height={200} width={200} />
          <p>Claiming...</p>
        </div>
      ) : (
        <>
          {/* Close Button */}
          <div className='close-button' onClick={closeModal}>
            &times;
          </div>

          <div className='card-image'>
            <img src={imageUrl} alt='' />
          </div>
          <div className='card-title' style={{ height: 50 }}>
            {itemName}
          </div>
          <div className='card-info' style={{ background: "transparent" }}>
            <div className='info-item'>
              <div className='info-title'>Sponsor</div>
              <div className='info-desc'>{itemSponsor}</div>
            </div>
            <div className='info-item'>
              <div className='info-title'>IRL Reward Description</div>
              <div className='info-desc'>{irlDescription}</div>
            </div>
          </div>
          {renderButton()}
        </>
      )}
    </div>
  );
};

export default ClaimCard;
