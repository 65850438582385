import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Input } from "antd";
import Header from "../../components/Layout/Header";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import axios from "axios";
import RewardProductCard from "../../components/RewardProductCard";
import { Triangle } from "react-loader-spinner";

function RewardPage() {
  interface Sponsorship {
    id: string;
    sponsor: string;
    description: string;
    logo?: string;
    itemURL?: string;
    [key: string]: any; // Include other potential properties
  }

  interface User {
    email: string;
    logo: string;
    websiteLink: string;
    [key: string]: any; // Include other potential properties
  }

  const [sponsorships, setSponsorships] = useState<any[]>([]);
  const [logo, setLogo] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const fetchSponsorships = async () => {
      try {
        let q;
        let p;
        q = collection(db, "Sponsorships");

        const snapshotSponsors = await getDocs(q);
        const sponsorshipsData: Sponsorship[] = snapshotSponsors.docs.map(
          (doc) => ({
            id: doc.id,
            sponsor: doc.data().sponsor || "",
            description: doc.data().description || "",
            ...doc.data(),
          })
        );
        console.log("sponsorships data", sponsorshipsData);
        // Extract sponsor emails
        const sponsorEmails: string[] = [];
        sponsorshipsData.forEach((sponsorship) => {
          sponsorEmails.push(sponsorship.sponsor);
        });
        console.log(sponsorEmails);

        const usersQuery = query(
          collection(db, "users"),
          where("email", "in", sponsorEmails)
        );
        const snapshotUsers = await getDocs(usersQuery);
        const userData: User[] = snapshotUsers.docs.map((doc) => ({
          id: doc.id,
          logo: doc.data().logo || "",
          email: doc.data().email || "",
          websiteLink: doc.data().websiteLink || "",
          ...doc.data(),
        }));
        console.log(userData);

        // Create a map of sponsor emails to their logos
        const emailToLogoMap: { [email: string]: string } = {};
        userData.forEach((user) => {
          emailToLogoMap[user.email] = user.logo;
        });

        // Add logos and itemURL to sponsorshipsData
        for (const sponsorship of sponsorshipsData) {
          sponsorship.logos = emailToLogoMap[sponsorship.sponsor]
            ? [emailToLogoMap[sponsorship.sponsor]]
            : [];
          if (sponsorship.id) {
            sponsorship.itemURL = await getItemImage(sponsorship.itemId);
          }
        }

        console.log(sponsorshipsData);
        setSponsorships(sponsorshipsData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching sponsorships:", error);
      }
    };

    fetchSponsorships();
  }, []); // Fetch sponsorships whenever the filter changes

  // Function to fetch username based on user ID
  async function getItemImage(itemId: string) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/itemImage/${itemId}`
      );
      return response.data.data[0].imageUrl;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  return (
    <div className='main-area'>
      <Header />

      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Triangle color='#00BFFF' height={200} width={200} />
          <p>Loading...</p>
        </div>
      ) : (
        <>
          <div className='slider-area creators unlock-real'></div>

          <div style={{ marginTop: 20 }} className='main-title-xl'>
            REWARDS MARKETPLACE
          </div>
          <div className='input-search'>
            <Input.Search
              className='search-input'
              placeholder='Search Rewards'
              size={"middle"}
              style={{ width: "60%" }}
            />
          </div>

          <div className='creator-sponsors-card'>
            <div className='creator-head-title' style={{ marginRight: 85 }}>
              <Button type='default' style={{ marginRight: 15 }}>
                <LeftOutlined />
              </Button>
              <div>Available Rewards</div>

              <Button type='default'>
                <RightOutlined />
              </Button>
            </div>
            <div className='creator-list-content' style={{ marginTop: 30 }}>
              {sponsorships.map((sponsorship) => (
                <RewardProductCard
                  logo={sponsorship.logos[0]}
                  itemURL={sponsorship.itemURL}
                  itemName={sponsorship.itemName}
                  itemDescription={sponsorship.description}
                  irlRewardDescription={sponsorship.irlRewardDescription}
                  itemId={sponsorship.itemId}
                  sponsorName={sponsorship.sponsorName}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default RewardPage;
