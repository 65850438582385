import type { ThemeConfig } from "antd";

const theme: ThemeConfig = {
  token: {
    colorPrimary: "#EE14FF",
  },

  components: {
    Input: {
      colorBgContainer: "#06050C",
      borderRadius: 8,
      colorBorder: "#A4A9B6",
      paddingInline: 12,
      paddingBlock: 11,
      fontSize: 16,
      colorTextPlaceholder: "#525C76",
      colorText: "#FFF",
      colorIcon: "#fff",
    },

    Button: {
      colorPrimary: "#EE14FF",
      defaultBg: "#201A2E",
      colorText: "#FBC7FF",
      borderRadius: 8,
      colorBorder: "#FBC7FF",
    },
    Select: {
      selectorBg: "#06050C",
      colorText: "#fff",
      colorBgElevated: "#06050C",
      optionSelectedBg: "#EE14FF",
      borderRadius: 8,
      colorBorder: "#A4A9B6",
      fontSize: 16,
      colorTextPlaceholder: "#525C76",
    },
  },
};

export default theme;
