import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import getFirestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCmX-l0DZ1sslu3ZtZfMcBOTsgjBIL2CJc",
  authDomain: "ugcverse.firebaseapp.com",
  projectId: "ugcverse",
  storageBucket: "ugcverse.appspot.com",
  messagingSenderId: "721714062610",
  appId: "1:721714062610:web:b1f88ff673a5480c117fcb",
  measurementId: "G-LT7YZS7S3H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app); // Get a reference to the Firestore database

export { app, analytics, db }; // Export app, analytics, and db
