"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelayOperations = void 0;
/**
 * @description transaction operation supported by the Relay-Server
 */
var RelayOperations;
(function (RelayOperations) {
    RelayOperations[RelayOperations["INVOKE"] = 0] = "INVOKE";
    RelayOperations[RelayOperations["DEPLOY"] = 1] = "DEPLOY";
    RelayOperations[RelayOperations["TRANSFER"] = 2] = "TRANSFER";
    RelayOperations[RelayOperations["NFT_TRANSFER"] = 3] = "NFT_TRANSFER";
})(RelayOperations = exports.RelayOperations || (exports.RelayOperations = {}));
