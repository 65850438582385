import { Button, Input } from "antd";
import Header from "../../components/Layout/Header";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import MemberCard from "../../components/MemberCard";
import { useEffect, useState } from "react";
import { db } from "../../firebase/firebase";
import {
  getDocs,
  query,
  collection,
  where,
  QueryDocumentSnapshot,
  DocumentData,
} from "firebase/firestore";
import axios from "axios";

interface Creator {
  id: string;
  robloxUserId: string;
  robloxGroupname: { groupId: string }[];
}

function CreatorsPage() {
  const [creators, setCreators] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(
          query(collection(db, "users"), where("playerType", "==", "Creator"))
        );

        const creatorData: Creator[] = [];
        const avatarPromises: Promise<string>[] = [];

        querySnapshot.forEach(
          async (doc: QueryDocumentSnapshot<DocumentData>) => {
            const creatorDataItem: Creator = {
              id: doc.id,
              robloxUserId: doc.data().robloxUserId,
              robloxGroupname: doc.data().robloxGroupname,
            };
            const avatarPromise = getFullAvatar(creatorDataItem.robloxUserId);
            avatarPromises.push(avatarPromise);

            creatorData.push(creatorDataItem);
          }
        );

        const avatars = await Promise.all(avatarPromises);

        const memberCards = creatorData.map(async (creator, index) => {
          const avatar = avatars[index];
          console.log(creator);
          const username = await getUsername(creator.robloxUserId);

          return (
            <MemberCard
              key={creator.id}
              avatar={avatar}
              displayNames={username}
              robloxId={creator.robloxUserId}
            />
          );
        });

        setCreators(await Promise.all(memberCards));
      } catch (error) {
        console.error("Error fetching creators:", error);
      }
    };

    fetchData();
  }, []);

  async function getGroupInfo(groupId: string) {
    try {
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/groupInfo/${groupId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching group info:", error);
    }
  }

  async function getFullAvatar(userId: string) {
    try {
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/fullAvatar/${userId}`
      );
      return response.data.data[0].imageUrl;
    } catch (error) {
      console.error("Error fetching full avatar:", error);
    }
  }

  async function getUsername(userId: string) {
    try {
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/username/${userId}`
      );
      return response.data.displayName;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  return (
    <div className='main-area'>
      <Header />
      <div className='slider-area creators unlock-real'></div>
      <div className='input-search' style={{ marginTop: "10vh" }}>
        <Input.Search
          className='search-input'
          placeholder='Search Creator'
          size={"middle"}
          style={{ width: "60%" }}
        />
      </div>
      <div className='category-list-container' style={{ marginTop: "10vh" }}>
        <div className='category-list-area'>
          <div className='category-list-title'>
            <Button type='default'>
              <LeftOutlined />
            </Button>
            <div className='title' style={{ fontFamily: "Lalezar" }}>
              MEMBERS
            </div>

            <Button type='default'>
              <RightOutlined />
            </Button>
          </div>
          <div className='category-list-content'>{creators}</div>
        </div>
      </div>
    </div>
  );
}

export default CreatorsPage;
