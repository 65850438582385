import logo from "../../assets/images/logo.png";
import CSDefaultButton from "../../components/CSDefaultButton";
import CSSegmented from "../../components/CSSegmented";
import "../../styles/login.scss";

import googleIcon from "../../assets/icons/google.svg";
import microsoftIcon from "../../assets/icons/lmicrosoft-icon.svg";
import React, { useState, useContext, useEffect } from "react";
import { KeycloakContext } from "../../context/keycloak";
import { Triangle } from "react-loader-spinner";

import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import axios from "axios";

type ButtonType = {
  applicableTo: any;
  name: string;
  html: React.ReactNode;
};

const LoginPage = () => {
  localStorage.setItem("redirectURL", "https://ugcdemo.com");
  const navigate = useNavigate();
  //Auth
  const auth = useContext(KeycloakContext);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const handleLogout = () => auth?.logoutSession();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    console.log("player type" + localStorage.getItem("playerType"));
    const checkLoggedIn = async () => {
      try {
        const account = JSON.parse(localStorage.getItem("account") || "");
        if (account) {
          setLoggedIn(true);
          console.log("User is already logged in");
          const email = account.email;
          const querySnapshot = await getDocs(
            query(collection(db, "users"), where("email", "==", email))
          );
          if (querySnapshot.docs.length > 0) {
            const user = querySnapshot.docs[0].data();
            if (user.playerType === "Sponsor") {
              localStorage.setItem("playerType", "Sponsor");
              if (
                !user.firstName ||
                !user.lastName ||
                !user.logo ||
                !user.websiteLink
              ) {
                navigate("/sign-up"); // Redirect to sign-up if any required field is missing
              } else {
                localStorage.setItem("avatar", user.logo);
                localStorage.setItem(
                  "username",
                  user.firstName + " " + user.lastName
                );
                navigate("/creators"); // Redirect to creators if all required fields are present
              }
            } else if (user.playerType === "Creator") {
              localStorage.setItem("playerType", "Creator");
              localStorage.setItem(
                "username",
                await getUsername(user.robloxUserId)
              );
              if (
                !user.firstName ||
                !user.lastName ||
                !user.robloxGroupname ||
                !user.robloxUGCProgram ||
                !user.robloxUserId
              ) {
                navigate("/sign-up"); // Redirect to sign-up if any required field is missing
              } else {
                localStorage.setItem(
                  "avatar",
                  await getAvatarHeadshot(user.robloxUserId || "")
                );
                navigate(`/creator/${user.robloxUserId}`); // Redirect to creator with robloxUserId
              }
            } else {
              localStorage.setItem("robloxId", user.robloxUserId);
              localStorage.setItem(
                "username",
                await getUsername(user.robloxUserId)
              );
              localStorage.setItem(
                "avatar",
                await getAvatarHeadshot(localStorage.getItem("robloxId") || "")
              );
              localStorage.setItem("playerType", "Player");
              navigate(`/players/${localStorage.getItem("robloxId")}`);
            }
          } else {
            // User is not registered, redirect to sign up page
            navigate("/sign-up");
          }
        } else {
          setLoggedIn(false);
          setLoading(false);
        }
      } catch (err) {
        console.error("Error:", err);
        auth?.logoutSession();
        setLoading(false);
      }
    };

    setTimeout(checkLoggedIn, 2000); // Run checkLoggedIn after a 1-second delay
  }, []);

  const [categoryItem, setCategoryItem] = useState<string>("");

  useEffect(() => {
    if (categoryItem) {
      localStorage.setItem("playerType", categoryItem);
    }
  }, [categoryItem]);

  const buttonList = [
    {
      name: "google",
      html: (
        <CSDefaultButton
          icon={<img src={googleIcon} alt='' width={36} height={36} />}
          text='Log in with Google'
          onClick={() => auth?.loginUsingIdp("google")}
        />
      ),
      applicableTo: ["Sponsor", "Creator", "Player"],
    },
    {
      name: "microsoft",
      html: (
        <CSDefaultButton
          icon={<img src={microsoftIcon} alt='' width={30} height={30} />}
          text='Log in with Microsoft'
          onClick={() => auth?.loginUsingIdp("microsoft")}
        />
      ),
      applicableTo: ["Sponsor"],
    },
    {
      name: "roblox",
      html: (
        <CSDefaultButton
          icon={<img src={logo} alt='' width={54} height={29} />}
          text='Log in with Roblox'
          onClick={() => auth?.loginUsingIdp("oidc")}
        />
      ),
      applicableTo: ["Creator", "Player"],
    },
  ] as ButtonType[];

  // Function to filter buttons for specific category and possibly for a specific section
  const filterButtonsForCategory = (category: string, ageGroup?: string) => {
    return buttonList.filter((btn) => {
      if (ageGroup === "13+") {
        return btn.applicableTo.includes(category) && btn.name === "roblox";
      } else if (ageGroup === "Under 13") {
        return btn.applicableTo.includes(category) && btn.name === "google";
      }
      return btn.applicableTo.includes(category);
    });
  };

  // Function to fetch username based on user ID
  async function getUsername(userId: string) {
    try {
      console.log(userId);
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/username/${userId}`
      );
      return response.data.displayName;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  // Function to fetch username based on user ID
  async function getAvatarHeadshot(userId: string) {
    try {
      console.log(userId);
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/headshot/${userId}`
      );
      return response.data.data[0].imageUrl;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  return (
    <div className='login-page-container'>
  {loading ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Triangle color="#00BFFF" height={200} width={200} />
      <p>Loading...</p>
    </div>
  ) : (
    <>
      <div className='logo'>
        <img src={logo} alt='Logo' />
      </div>
      <CSSegmented
        options={["Sponsor", "Creator", "Player"]}
        value={categoryItem}
        setValue={setCategoryItem}
      />
      <div className='login-area'>
        <div className='lg-title'>Login</div>
        {categoryItem === "Sponsor" && (
          <div className='btn-groups'>
            {filterButtonsForCategory("Sponsor", "").map((btn, index) => (
              <React.Fragment key={index}>{btn.html}</React.Fragment>
            ))}
          </div>
        )}
        {["Creator", "Player"].includes(categoryItem) && (
          <>
            <div className='age-section'>
              <h2>13+</h2>
              <div className='btn-groups'>
                {filterButtonsForCategory(categoryItem, "13+").map(
                  (btn, index) => (
                    <React.Fragment key={index}>{btn.html}</React.Fragment>
                  )
                )}
              </div>
            </div>
            <div className='age-section'>
              <h2>Under 13</h2>
              <div className='btn-groups'>
                {filterButtonsForCategory(categoryItem, "Under 13").map(
                  (btn, index) => (
                    <React.Fragment key={index}>{btn.html}</React.Fragment>
                  )
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )}
</div>
  )
}

export default LoginPage;
