import React, { useContext, useEffect, useState } from "react";
import { KeycloakContext } from "../../context/keycloak";
import CSDefaultButton from "../../components/CSDefaultButton";
import "./tgaLogin.css";
import googleIcon from "../../assets/icons/google.svg";
import microsoftIcon from "../../assets/icons/lmicrosoft-icon.svg";
import robloxIcon from "../../assets/images/logo.png";
import { Triangle } from "react-loader-spinner";

export default function TGALogin() {
  const auth = useContext(KeycloakContext);
  localStorage.setItem("redirectURL", "https://www.ugcdemo.com/tgalogin");
  const handleLogout = () => auth?.logoutSession();
  const handleRefresh = async () => await auth?.refreshSession(-1);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkLoggedIn = async () => {
      try {
        console.log(localStorage.getItem("account"));
        if (JSON.parse(localStorage.getItem("account") as string)) {
          await handleRefresh();
          console.log("User is already logged in");
          window.opener.postMessage("Logged in");
          window.close();
        }
      } catch (err) {
        console.error("Error:", err);
        await handleLogout();
        setLoading(false);
      }
    };

    setTimeout(checkLoggedIn, 2000);
  }, []);

  return loading ? (
    <div className="loading-container">
      <Triangle color='#00BFFF' height={200} width={200} />
      <p>Loading...</p>
    </div>
  ) : (
    <main className="tgalogin-page-container">
      <section className="login-area">
        <h1 className="lg-title">Under 13</h1>
        <div className="btn-groups">
          <CSDefaultButton
            icon={<img src={googleIcon} alt='' width={36} height={36} />}
            text='Login with Google'
            onClick={() => auth?.loginUsingIdp("google")}
          />
          <CSDefaultButton
            icon={<img src={microsoftIcon} alt='' width={30} height={30} />}
            text='Login with Microsoft'
            onClick={() => auth?.loginUsingIdp("microsoft")}
          />
        </div>
      </section>
      <section className="login-area">
        <h1 className="lg-title">13+</h1>
        <div className="btn-groups">
          <CSDefaultButton
            icon={<img src={robloxIcon} alt='' width={36} height={36} />}
            text='Login with Roblox'
            onClick={() => auth?.loginUsingIdp("roblox")}
          />
        </div>
      </section>
    </main>
  );
}