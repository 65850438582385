import { FC, useContext, useEffect, useState } from "react";
import { Button, Input, Upload, Radio } from "antd";
import { SearchOutlined, UploadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import axios, { AxiosResponse } from "axios";

import IAccount from "../../types/IAccount";
import { KeycloakContext } from "../../context/keycloak";

type IProps = {
  onClickNext: (accountValue: any) => void;
};

interface IUser {
  id: number;
  name: string;
  avatarUrl?: string; // Optional since not all users might have an avatar
}

const SignUpForm: FC<IProps> = ({ onClickNext }) => {
  const navigate = useNavigate();
  const playerType = localStorage.getItem("playerType") || "";
  const account = JSON.parse(localStorage.getItem("account") || "");
  const [robloxId, setRobloxId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = useContext(KeycloakContext);
  const handleLogout = () => auth?.logoutSession();

  const [submitData, setSubmitData] = useState<IAccount>({
    firstName: "",
    lastName: "",
    logo: "",
    robloxUserId: robloxId,
    robloxGroupname: [],
    websiteLink: "",
    robloxUGCProgram: "",
    playerType: playerType,
    email: account.email || "",
    companyName: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitData({ ...submitData, [e.target.name]: e.target.value });
  };
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState<IUser[]>([]); // Initialize with an empty array of IUser
  const [nextPageCursor, setNextPageCursor] = useState("");
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to manage dropdown open/close
  const [userIds, setUserIds] = useState(""); // State to manage dropdown open/close
  const [verificationWord, setVerificationWord] = useState("");
  const [verificationErrorMessage, setVerificationErrorMessage] = useState(""); // Add state for error message

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(
        query(collection(db, "users"), where("email", "==", account.email))
      );
      if (querySnapshot.docs.length > 0) {
        const userData = querySnapshot.docs[0].data();
        if (userData.playerType === "Sponsor") {
          if (
            userData.firstName &&
            userData.lastName &&
            userData.logo &&
            userData.websiteLink &&
            userData.companyName
          ) {
            navigate("/"); // Redirect to creators if all required fields are present
            return; // Exit useEffect early
          }
        } else if (userData.playerType === "Creator") {
          if (
            userData.firstName &&
            userData.lastName &&
            userData.robloxGroupname &&
            userData.robloxUGCProgram
          ) {
            navigate(`/`); // Redirect to creator with robloxUserId
            return; // Exit useEffect early
          }
        } else if (userData.playerType === "Player") {
          if (userData.robloxUserId) {
            navigate(`/`); // Redirect to creator with robloxUserId
            return; // Exit useEffect early
          }
        }
      }
    };

    fetchData();
  }, [playerType, navigate]);

  useEffect(() => {
    const getId = async () => {
      try {
        const robloxUser = await getRobloxId();
        if (robloxUser) {
          setRobloxId(robloxUser);
          if (localStorage.getItem("playerType") === "Player") {
            await handleSignUp();
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getId();
  });

  async function getRobloxId() {
    try {
      const account = JSON.parse(localStorage.getItem("account") || "");
      const token = account.token; // Replace this with your actual bearer token
      const response = await axios.get(
        "https://dplat.zbyte.io/kc/realms/community/protocol/openid-connect/userinfo",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data.roblox_id;
    } catch (error) {
      // Handle errors here
      console.error("Error fetching user info:", error);
      localStorage.setItem("account", "");
      navigate("/");
    }
  }

  async function verifyOwner(userId: string, verifierWord: string) {
    try {
      // Make a GET request to fetch groups
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/verifyDescription/${userId}/${verifierWord}`
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching owner:", error);
    }
  }

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/searchUsername/${searchInput}`
      );
      const userData = response.data;
      const users = userData
        .map((user: any[]) => user[0]) // Map to get user IDs
        .sort((a: number, b: number) => a - b) // Sort numerically from smallest to largest
        .join(","); // Join into a string with commas

      // Fetch avatars for the user IDs
      const avatarResponse = await axios.get(
        `https://ugc-verse-backend.vercel.app/searchUsernameThumbnails/${users.toString()}`
      );
      const avatars = avatarResponse.data.thumbnails;
      // Combine user data with avatars
      const combinedResults = userData.map((user: any[]) => ({
        id: user[0],
        name: user[1],
        avatarUrl: avatars[user[0]]
          ? avatars[user[0]].url
          : "../../assets/images/angel.svg", // Use default avatar if not available
      }));

      setSearchResults(combinedResults);
      console.log(searchResults);
      setDropdownOpen(true); // Open the dropdown after search
    } catch (error) {
      console.error("Error searching for users:", error);
    }
  };

  const generateRandomWord = () => {
    const chars = "abcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };

  const handleUserSelect = async (userId: number, userName: string) => {
    setSelectedUserId(userId);
    setDropdownOpen(false); // Close the dropdown after selecting a user
    setSearchInput(userName); // Update the search input with the selected user's name
    setSelectedUserId(userId);
    localStorage.setItem("robloxId", userId.toString());
    const randomWord = generateRandomWord();
    setVerificationWord(randomWord);
    console.log(verificationWord);
    const groupNames = await resolveGroups();
    console.log(groupNames);
    setSubmitData({
      ...submitData,
      robloxUserId: userId.toString(),
      robloxGroupname: groupNames,
    });
    console.log(submitData);
    console.log(groupNames);
  };

  const handleSignUp = async () => {
    console.log(submitData);
    if (robloxId) {
      console.log(account);
      localStorage.setItem("robloxId", robloxId);
      const groupNames = await resolveGroups();
      console.log(groupNames);
      setSubmitData({
        ...submitData,
        robloxUserId: robloxId,
        robloxGroupname: groupNames,
      });
      setIsSubmitting(true);
      return;
    } else {
      setIsSubmitting(true); // Indicate that submission has started
      return;
    }
  };

  useEffect(() => {
    if (!isSubmitting) return;

    const finalizeSignUp = async () => {
      const account = JSON.parse(localStorage.getItem("account") || "");
      console.log(submitData);
      if (!robloxId && playerType !== "Sponsor") {
        try {
          if (
            !(await verifyOwner(
              submitData.robloxUserId || "",
              verificationWord
            ))
          ) {
            console.log("Not verified");
            setVerificationErrorMessage(
              "Verification failed. Please make sure the About section of your Roblox profile contains the above word."
            );
            setIsSubmitting(false);
            console.log(verificationWord);
            return;
          }
        } catch (err) {
          console.error("Error verifying owner: ", err);
        }
      }

      try {
        const userDocRef = doc(db, "users", submitData.email);
        await setDoc(userDocRef, submitData);
        console.log("Document written with ID: ", submitData.email);
        onClickNext(submitData);
        if (playerType === "Sponsor") {
          navigate("/");
        } else if (playerType === "Creator") {
          navigate("/");
        } else if (playerType === "Player") {
          navigate("/");
        }
      } catch (error) {
        console.error("Error adding document: ", error);
      } finally {
        setIsSubmitting(false); // Ensure this runs even if there's an error
      }
    };

    finalizeSignUp();
  }, [submitData, isSubmitting]);

  // Depend on submitData and isSubmitting

  // Define an interface for the group object structure
  interface GroupData {
    group: {
      id: number;
      name: string;
    };
    role: {
      id: number;
      name: string;
      rank: number;
    };
  }

  async function resolveGroups() {
    if (playerType === "Creator") {
      try {
        const ownerGroups = await getGroups(
          localStorage.getItem("robloxId") || ""
        );
        console.log(localStorage.getItem("robloxId"));
        console.log(ownerGroups);
        if (ownerGroups.length === 0) {
          console.log("No groups");
          // Add the additional group name to the list
          const robloxGroupname: {
            groupName: string;
            groupId: string;
          }[] = [];
          robloxGroupname.push({
            groupName: "Player inventory",
            groupId: localStorage.getItem("robloxId") || "",
          });
          return robloxGroupname;
        } else {
          const robloxGroupname = ownerGroups.map(
            (group: { group: { name: any; id: { toString: () => any } } }) => ({
              groupName: group.group.name,
              groupId: group.group.id.toString(),
            })
          );
          robloxGroupname.push({
            groupName: "Player inventory",
            groupId: localStorage.getItem("robloxId") || "",
          });
          return robloxGroupname;
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
        return []; // Return an empty array in case of error
      }
    }
    return []; // Return an empty array if playerType is not "Creator"
  }

  const handleFileUpload = (event: any) => {
    const reader = new FileReader();
    reader.readAsDataURL(event.fileList[0].originFileObj as Blob);
    reader.onload = () => {
      if (reader.result) {
        // Check if reader.result is not null
        setSubmitData({ ...submitData, logo: reader.result.toString() });
      }
    };
  };

  async function getGroups(userId: string) {
    try {
      // Make a GET request to fetch groups
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/groups/${userId}`
      );
      // Filter the response data to include only the groups where the role is "Owner"
      const ownerGroups = response.data.filter(
        (group: any) =>
          group.role.name.includes("Owner") || group.role.name.includes("Admin")
      );
      return ownerGroups;
    } catch (error) {
      console.error("Error fetching groups:", error);
      return []; // Return an empty array in case of error
    }
  }

  const isFormFilled = () => {
    if (playerType === "Sponsor") {
      return (
        submitData.firstName &&
        submitData.lastName &&
        submitData.websiteLink &&
        submitData.companyName &&
        submitData.logo
      );
    } else if (playerType === "Player") {
      if (robloxId !== "") {
        return robloxId;
      } else {
        return submitData.robloxUserId;
      }
    } else if (playerType === "Creator") {
      if (robloxId !== "") {
        return (
          submitData.firstName &&
          submitData.lastName &&
          submitData.robloxUGCProgram &&
          robloxId
        );
      } else {
        return (
          submitData.firstName &&
          submitData.lastName &&
          submitData.robloxUGCProgram &&
          submitData.robloxUserId
        );
      }
    }
    return false;
  };

  return (
    <div className='sign-form-area'>
      <div className='form-item-content'>
        {playerType === "Sponsor" && (
          <div className='form-item-content column-rezerve'>
            <div className='form-item-content'>
              <div className='input-area'>
                <div className='input-title'>First Name*</div>
                <div className='input-content'>
                  <Input
                    name='firstName'
                    placeholder='first name'
                    value={submitData.firstName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='input-area'>
                <div className='input-title'>Last Name*</div>
                <div className='input-content'>
                  <Input
                    name='lastName'
                    placeholder='last name'
                    value={submitData.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className='input-area'>
              <div className='input-title'>Logo</div>
              <div className='input-content'>
                {submitData.logo && (
                  <div style={{ display: "flex" }}>
                    <img
                      className='cs-avatar-img'
                      src={submitData.logo}
                      alt='logo'
                    />
                    <button
                      onClick={() => {
                        setSubmitData({ ...submitData, logo: undefined });
                      }}
                      style={{ marginLeft: 15, height: 50 }}
                    >
                      Clear{" "}
                    </button>
                  </div>
                )}

                {!submitData.logo && (
                  <Upload
                    onChange={handleFileUpload}
                    className='cs-avatar-uploader'
                    name='avatar'
                    style={{
                      position: "relative",
                      display: submitData.logo ? "flex" : "inline-block",
                    }}
                    showUploadList={false}
                  >
                    {!submitData.logo && (
                      <div className='uploader-title'>
                        upload your logo here
                      </div>
                    )}
                    <button className='upload-picture-btn' type='button'>
                      <UploadOutlined /> Browse Files
                    </button>
                  </Upload>
                )}
              </div>
            </div>

            <div className='input-area-multi'>
              <div className='input-area'>
                <div className='input-title'>Website Link*</div>
                <div className='input-content'>
                  <Input
                    name='websiteLink'
                    placeholder='www.example.com'
                    value={submitData.websiteLink}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className='input-area-multi'>
              <div className='input-area'>
                <div className='input-title'>Company Name*</div>
                <div className='input-content'>
                  <Input
                    name='companyName'
                    placeholder='Example Inc.'
                    value={submitData.companyName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {playerType === "Creator" && (
          <div className='input-area'>
            <div className='form-item-content'>
              <div className='input-area'>
                <div className='input-title'>First Name*</div>
                <div className='input-content'>
                  <Input
                    name='firstName'
                    placeholder='first name'
                    value={submitData.firstName}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='input-area'>
                <div className='input-title'>Last Name*</div>
                <div className='input-content'>
                  <Input
                    name='lastName'
                    placeholder='last name'
                    value={submitData.lastName}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className='input-title'>
              Are you a part of the Roblox UGC Program?
            </div>
            <div className='input-content'>
              <Radio.Group
                onChange={(e) =>
                  setSubmitData({
                    ...submitData,
                    robloxUGCProgram: e.target.value,
                  })
                }
                value={submitData.robloxUGCProgram}
              >
                <Radio style={{ color: "white" }} value={"Yes"}>
                  Yes
                </Radio>
                <Radio style={{ color: "white" }} value={"No"}>
                  No
                </Radio>
              </Radio.Group>
            </div>
            {!robloxId && (
              <div className='input-area'>
                <div className='input-area'>
                  <div className='input-title'>Search for Roblox Username</div>
                  <div className='input-content'>
                    <Input
                      placeholder='Enter Roblox Username'
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      suffix={<SearchOutlined />}
                    />
                    <Button
                      type='primary'
                      onClick={handleSearch}
                      style={{ marginTop: 10 }}
                    >
                      Search
                    </Button>
                    {verificationWord && (
                      <div className='verification-word'>
                        <p>
                          Please add the following word to the About section of
                          your Roblox profile to verify ownership:
                        </p>
                        <strong>{verificationWord}</strong>
                      </div>
                    )}
                    {verificationErrorMessage && (
                      <div className='error-message'>
                        <p style={{ color: "red" }}>
                          {verificationErrorMessage}
                        </p>
                      </div>
                    )}
                  </div>
                  {dropdownOpen && (
                    <div className='search-results-container customScroll'>
                      {/* Scrollable container for search results */}
                      <div className='search-results'>
                        {searchResults.map((user: IUser) => (
                          <div
                            key={user.id}
                            className='search-result-item'
                            onClick={() => handleUserSelect(user.id, user.name)}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                              padding: "5px",
                            }}
                          >
                            {user.avatarUrl && (
                              <img
                                src={user.avatarUrl}
                                alt={`${user.name}'s avatar`}
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  marginRight: "10px",
                                  borderRadius: "50%",
                                }}
                              />
                            )}
                            {user.name.toUpperCase()}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {playerType === "Player" && !robloxId && (
          <div className='input-area'>
            <div className='input-title'>Search for Roblox Username</div>
            <div className='input-content'>
              <Input
                placeholder='Enter Roblox Username'
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <p>
                Don't see your username?{" "}
                <a
                  href='https://www.rolimons.com/players'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Go here
                </a>
                , click Add Player, enter your username, and click Add. Then
                come back and search again.
              </p>

              <Button
                type='primary'
                onClick={handleSearch}
                style={{ marginTop: 20 }}
              >
                Search
              </Button>
            </div>
            {verificationWord && (
              <div className='verification-word'>
                <p>
                  Please add the following word to the About section of your
                  Roblox profile to verify ownership:
                </p>
                <strong>{verificationWord}</strong>
              </div>
            )}
            {verificationErrorMessage && (
              <div className='error-message'>
                <p style={{ color: "red" }}>{verificationErrorMessage}</p>
              </div>
            )}
            {dropdownOpen && (
              <div className='search-results-container customScroll '>
                {/* Scrollable container for search results */}
                <div className='search-results '>
                  {searchResults.map((user: IUser) => (
                    <div
                      key={user.id}
                      className='search-result-item'
                      onClick={() => handleUserSelect(user.id, user.name)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                    >
                      {user.avatarUrl && (
                        <img
                          src={user.avatarUrl}
                          alt={`${user.name}'s avatar`}
                          style={{
                            width: "80px",
                            height: "80px",
                            marginRight: "10px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      {user.name.toUpperCase()}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type='primary'
          style={{ width: 273, height: 48 }}
          className='signup-btn'
          onClick={handleSignUp}
          disabled={!isFormFilled()} // Disable the button if form is not filled
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default SignUpForm;
