import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Layout/Header";

function PlayerPage() {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/players/21`;
    navigate(path);
  };
  return (
    <div className='main-area'>
      <Header />
      <div className='main-title-xl'>Search Players</div>
      <div className='input-search'>
        <Input.Search
          className='search-input'
          placeholder='Search Player'
          size={"middle"}
          style={{ width: "60%" }}
        />
      </div>
      <div className='creator-sponsors-card'>
        <div className='creator-list-content'></div>
      </div>
    </div>
  );
}

export default PlayerPage;
