"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAINNET = exports.TESTNET = exports.HBAR = exports.MATIC = exports.AVAX = exports.CHAIN_ID_HBAR_MAINNET = exports.CHAIN_ID_HBAR_TESTNET = exports.CHAIN_ID_AVAX_MAINNET = exports.CHAIN_ID_AVAX_TESTNET = exports.CHAIN_ID_MATIC_MAINNET = exports.CHAIN_ID_MATIC_TESTNET = void 0;
exports.CHAIN_ID_MATIC_TESTNET = 80002;
exports.CHAIN_ID_MATIC_MAINNET = 137;
exports.CHAIN_ID_AVAX_TESTNET = 43113;
exports.CHAIN_ID_AVAX_MAINNET = 43114;
exports.CHAIN_ID_HBAR_TESTNET = 296;
exports.CHAIN_ID_HBAR_MAINNET = 295;
exports.AVAX = 'avax';
exports.MATIC = 'matic';
exports.HBAR = 'hbar';
exports.TESTNET = 'Testnet';
exports.MAINNET = 'Mainnet';
