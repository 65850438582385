import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import { Modal } from "antd";
import ClaimCard from "../ClaimCard"; // Import the ClaimCard component

type IProps = {
  imageUrl: string;
  itemName: string;
  itemCreator?: string;
  itemSponsor?: string;
  itemId: string;
  irlDescription: string;
  claimURL: string;
  description: string;
  isClickable: boolean;
  claimed: boolean;
};

const PlayerDetailProductCard: FC<IProps> = ({
  imageUrl,
  itemName,
  itemCreator,
  itemSponsor,
  itemId,
  irlDescription,
  claimURL,
  description,
  isClickable,
  claimed,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleCardClick = () => {
    if (isClickable) {
      // Only open modal if isClickable is true
      setShowModal(true);
    }
  };

  const closeModal = () => {
    console.log("Closing modal");
    console.log(showModal);
    setShowModal(!showModal);
    console.log(showModal);
  };

  return (
    <>
      <div
        className={`category-card-item`}
        style={{
          height: 397,
          padding: 0,
          border: "1px solid #fbc7ff",
          flexBasis: 250,
          width: 250,
        }}
        onClick={handleCardClick}
      >
        <div className='card-image'>
          <img src={imageUrl} alt='' style={{ width: 210, height: 150 }} />
        </div>
        <div
          className='card-info'
          style={{
            background: "black",
            marginBottom: 0,
            borderBottomRightRadius: "8%",
            borderBottomLeftRadius: "8%",
          }}
        >
          <div className='info-item'>
            <div className='info-title'>Item</div>
            <div className='info-desc'>{itemName}</div>
          </div>
          {itemCreator && (
            <div className='info-item'>
              <div className='info-title'>Creator</div>
              <div className='info-desc'>{itemCreator}</div>
            </div>
          )}
          {itemSponsor && (
            <div className='info-item' style={{ marginBottom: 10 }}>
              <div className='info-title'>Sponsor</div>
              <div className='info-desc'>{itemSponsor}</div>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <div className='modal-background'>
          <div className='modal-content'>
            {/* Render the ClaimCard component inside the modal */}
            <ClaimCard
              imageUrl={imageUrl}
              itemName={itemName}
              itemCreator={itemCreator}
              itemId={itemId}
              irlDescription={irlDescription}
              claimURL={claimURL}
              description={description}
              claimed={claimed}
              itemSponsor={itemSponsor}
              isButton={isClickable}
              closeModal={closeModal}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PlayerDetailProductCard;
