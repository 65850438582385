import React, { FC } from "react";
import "./style.scss";
import { Button } from "antd";
import { Link } from "react-router-dom";

type IProps = {
  logo: string;
  itemName: string;
  itemDescription: string;
  irlRewardDescription: string;
  itemId: string;
  setShowModal: (close: boolean) => void;
};

const RewardModal: FC<IProps> = ({
  logo,
  itemName,
  itemDescription,
  irlRewardDescription,
  itemId,
  setShowModal,
}) => {
  const formatItemNameForUrl = (name: string) => {
    return name.split(" ").join("-");
  };

  const itemUrl = `https://www.roblox.com/catalog/${itemId}/${formatItemNameForUrl(
    itemName
  )}`;

  function closeModal(): void {
    setShowModal(false);
  }

  return (
    <div className={`category-card-item`} style={{ width: 600 }}>
      <div className='close-button' onClick={closeModal}>
        &times;
      </div>
      <div className='card-image'>
        <img src={logo} alt='' />
      </div>
      <div
        className='card-info'
        style={{ height: 215, padding: 5, background: "transparent" }}
      >
        <div className='info-item'>
          <div className='info-title' style={{ flex: "1 1" }}>
            Item:{" "}
          </div>
          <div className='info-desc'>{itemName}</div>
        </div>
        <div className='info-item'>
          <div className='info-title' style={{ flex: "1 1" }}>
            Description:{" "}
          </div>
          <div className='info-desc'>{itemDescription}</div>
        </div>
        <div className='info-item'>
          <div className='info-title' style={{ flex: "1 1" }}>
            Reward:{" "}
          </div>
          <div className='info-desc'>{irlRewardDescription}</div>
        </div>
      </div>{" "}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          type='primary'
          onClick={() => window.open(itemUrl)}
          style={{ width: 150 }}
        >
          Buy
        </Button>
      </div>
    </div>
  );
};

export default RewardModal;
