import { FC, useState } from "react";
import "./style.scss";
import { Button } from "antd";
import RewardModal from "../RewardModal";

type IProps = {
  logo: string;
  itemURL: string;
  itemName: string;
  itemDescription: string;
  irlRewardDescription: string;
  itemId: string;
  sponsorName: string;
};

const RewardProductCard: FC<IProps> = ({
  logo,
  itemURL,
  itemName,
  itemDescription,
  irlRewardDescription,
  itemId,
  sponsorName,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleCardClick = () => {
    // Toggle the visibility of the modal
    setShowModal(!showModal);
  };

  return (
    <>
      <div
        className='category-card-item'
        style={{
          padding: 0,
          border: "1px white solid",
          flexBasis: 227,
          height: 311,
          borderRadius: 25,
          borderBottomLeftRadius: 25,
        }}
        onClick={handleCardClick}
      >
        <div className='card-image'>
          <img src={itemURL} alt='reward' className='card-image-content' />
        </div>
        <div
          className='card-title'
          style={{
            background: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: "white",
            fontSize: 13,
            marginBottom: 0,
            width: "100%",
            height: 150,
            borderBottomLeftRadius: 25,
            borderBottomRightRadius: 25,
            fontFamily: "Lalezar",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "80%",
              marginTop: 10,
            }}
          >
            <div style={{ fontWeight: 100, fontSize: 18 }}>ITEM:</div>
            <div style={{ marginLeft: 45, fontFamily: "Inter", fontSize: 12 }}>
              {" "}
              {itemName.toUpperCase()}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              justifyItems: "center",
              width: "80%",
              marginTop: 10,
            }}
          >
            <div style={{ fontWeight: 100, fontSize: 18 }}>SPONSOR:</div>
            <div style={{ fontFamily: "Inter", fontSize: 12 }}>
              {sponsorName}
            </div>
          </div>

          <div className='card-image'></div>
        </div>
      </div>
      {showModal && (
        <div className='modal-background' onClick={handleCardClick}>
          <div className='modal-content'>
            <RewardModal
              logo={logo}
              itemName={itemName}
              itemDescription={itemDescription}
              irlRewardDescription={irlRewardDescription}
              itemId={itemId}
              setShowModal={setShowModal}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RewardProductCard;
