import axios from "axios";
import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { Triangle } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import CSSegmented from "../../components/CSSegmented";
import Header from "../../components/Layout/Header";
import PlayerDetailProductCard from "../../components/PlayerDetailProductCard";
import { KeycloakContext } from "../../context/keycloak";
import { db } from "../../firebase/firebase";

interface UserItem {
  assetType: number;
  creatorHasVerifiedBadge: boolean;
  creatorName: string;
  creatorTargetId: number;
  creatorType: string;
  description: string;
  favoriteCount: number;
  id: number;
  isOffSale: boolean;
  itemRestrictions: any[]; // Define the correct type if it's not any
  itemStatus: any[]; // Define the correct type if it's not any
  itemType: string;
  name: string;
  offSaleDeadline: any; // Define the correct type if it's not any
  priceStatus: string;
  productId: number;
  purchaseCount: number;
  saleLocationType: string;
}

function PlayerDetailPage() {
  const { robloxUserId } = useParams();
  const [categoryItem, setCategoryItem] = useState<string>("REWARDS");
  const [dataStale, setDataStale] = useState<boolean>(false);
  const [isPlayer, setIsPlayer] = useState<boolean>(false);
  const [allUserItems, setUserItems] = useState<any[]>([]);
  const auth = useContext(KeycloakContext);
  const handleRefresh = async () => await auth?.refreshSession(-1);
  const [userAvatar, setUserAvatar] = useState<string>();
  const [loading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(
          query(
            collection(db, "users"),
            where("robloxUserId", "==", robloxUserId)
          )
        );
        if (querySnapshot.docs.length > 0) {
          const account = JSON.parse(localStorage.getItem("account") || "");
          if (account) {
            const email = account.email;
            const querySnapshot = await getDocs(
              query(collection(db, "users"), where("email", "==", email))
            );
            if (querySnapshot.docs.length > 0) {
              const user = querySnapshot.docs[0].data();
              if (user.robloxUserId == robloxUserId) {
                setIsPlayer(true);
              }
            }
          }

          if (robloxUserId) {
            const itemsData = await retrieveUserSponsoredItems(robloxUserId);
            const allUserItems = [];

            for (const item of itemsData) {
              const itemData = await getItemData(item);
              allUserItems.push(itemData);
            }

            const userItemsData = allUserItems;
            const itemIds = userItemsData.map((item: any) => item.id);
            const itemImagePromises = itemIds.map((itemId: string) =>
              getItemImage(itemId)
            );
            const itemImages = await Promise.all(itemImagePromises);
            const userItems = userItemsData.map((item: any, index: number) => ({
              ...item,
              url: itemImages[index],
            }));
            // Retrieve sponsor names for user items
            const userItemsWithSponsors = await retrieveSponsorNames(userItems);
            const userItemsWithClaimed = await addClaimed(
              userItemsWithSponsors,
              robloxUserId
            );

            // Update state with user items including sponsor names
            setUserItems(userItemsWithClaimed);
            console.log(setUserItems);
            uploadItemsToDB(userItemsWithClaimed);
          }
        }
        // Set state or do other tasks based on the result
      } catch (error) {
        // Handle errors
      }

      const avatar = await getFullAvatar(robloxUserId || "");
      setUserAvatar(avatar);
      setIsLoading(false);
    };

    fetchData(); // Immediately invoke the async function
  }, [robloxUserId]); // Add robloxUserId as a dependency

  async function uploadItemsToDB(userItemsData: UserItem[]) {
    try {
      // Perform a Firestore query to find the user document based on the Roblox user ID
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("robloxUserId", "==", robloxUserId)
        )
      );

      // Check if the query returned any documents
      if (!querySnapshot.empty) {
        // Get the first document (assuming there's only one user document per Roblox user)
        const userDoc = querySnapshot.docs[0];
        const userId = userDoc.id; // Get the user document ID

        // Update the user document with the new items array
        await updateDoc(doc(db, "users", userId), {
          items: userItemsData,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function getItemData(itemId: string) {
    // Retrieve all user documents
    const userQuerySnapshot = await getDocs(collection(db, "users"));

    // Array to store the item data
    let itemData = null;

    // Iterate through each user document
    userQuerySnapshot.forEach(
      (userDoc: QueryDocumentSnapshot<DocumentData>) => {
        const userData = userDoc.data();
        // Check if the user has robloxGroupname
        if (userData.robloxGroupname) {
          // Iterate through each group under robloxGroupname
          Object.values(userData.robloxGroupname).forEach((group: any) => {
            // Check if the group has items
            if (group.items) {
              // Iterate through each item of the group
              Object.values(group.items).forEach((item: any) => {
                if (item && item.id !== undefined) {
                  // Check if the item id matches the provided itemId
                  if (item.id.toString() === itemId.toString()) {
                    // Store the item data and exit the loop
                    itemData = item;
                  }
                }
              });
            }
          });
        }
      }
    );

    return itemData;
  }

  // Function to fetch username based on user ID
  async function getItemImage(itemId: string) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/itemImage/${itemId}`
      );
      return response.data.data[0].imageUrl;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  async function retrieveSponsorNames(userItems: any[]) {
    try {
      // Retrieve all sponsor documents
      const sponsorQuerySnapshot = await getDocs(
        collection(db, "Sponsorships")
      );

      // Map each sponsor document to its data
      const sponsors = sponsorQuerySnapshot.docs.map((doc) => doc.data());

      // Iterate over userItems and find matching sponsors
      const userItemsWithSponsors = userItems.map((item) => {
        console.log(item.id);
        console.log(sponsors);
        // Find the sponsor with matching itemId
        const matchingSponsor = sponsors.find(
          (sponsor) => sponsor.itemId.toString() === item.id.toString()
        );

        // If matching sponsor found, add sponsorName to item
        if (matchingSponsor) {
          return {
            ...item,
            sponsorName: matchingSponsor.sponsorName,
            irlRewardDescription: matchingSponsor.irlRewardDescription,
            claimURL: matchingSponsor.claimURL,
          };
        } else {
          // If no matching sponsor found, set sponsorName to 'Unknown'
          return { ...item, sponsorName: "N/A" };
        }
      });

      return userItemsWithSponsors;
    } catch (error) {
      console.error("Error retrieving sponsor names:", error);
      return userItems; // Return user items without sponsor names in case of error
    }
  }

  async function retrieveUserSponsoredItems(userId: string) {
    try {
      // Retrieve all sponsor documents
      const sponsorQuerySnapshot = await getDocs(
        collection(db, "Sponsorships")
      );

      // Map each sponsor document to its data
      const sponsors = sponsorQuerySnapshot.docs.map((doc) => doc.data());

      // Initialize an array to store user-owned items
      const userOwnedItems = [];

      // Iterate through each item in the sponsor document
      for (const sponsor of sponsors) {
        console.log(sponsor);
        // Check if the user owns the item
        console.log(await checkIfUserOwns(userId, sponsor.itemId));
        if (await checkIfUserOwns(userId, sponsor.itemId)) {
          // If true, add it to the array
          userOwnedItems.push(sponsor.itemId);
        }
      }
      return userOwnedItems;
    } catch (error) {
      // Handle the error
      console.error("Error retrieving user-owned sponsored items:", error);
      throw error; // Rethrow the error if needed
    }
  }

  async function addClaimed(userItems: any[], robloxUserId: string) {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("robloxUserId", "==", robloxUserId)
        )
      );

      // Check if the query returned any documents
      if (!querySnapshot.empty) {
        // Get the first document
        const playerDoc = querySnapshot.docs[0];
        const playerData = playerDoc.data();

        // Check if the player document contains the 'items' field
        if (playerData && playerData.items) {
          // Map each userItem to add the claimed field
          const userItemsWithClaimed = userItems.map((item) => {
            // Check if the player has claimed the item
            const claimed = playerData.items.some(
              (playerItem: any) =>
                playerItem.id.toString() === item.id.toString() &&
                playerItem.claimed === true
            );

            return {
              ...item,
              claimed: claimed,
            };
          });

          return userItemsWithClaimed;
        } else {
          console.log("Player document does not contain items field.");
          return userItems;
        }
      } else {
        console.error(
          "Player document not found for Roblox user ID:",
          robloxUserId
        );
        return userItems;
      }
    } catch (error) {
      console.error("Error retrieving claimed items:", error);
      return userItems; // Return user items in case of error
    }
  }

  async function checkIfUserOwns(creatorId: string, itemId: string) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/checkIfUserOwns/${creatorId}/${itemId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  // Function to fetch username based on user ID
  async function getFullAvatar(userId: string) {
    try {
      // Make a GET request to fetch username
      const response = await axios.get(
        `https://ugc-verse-backend.vercel.app/fullAvatar/${userId}`
      );
      return response.data.data[0].imageUrl;
    } catch (error) {
      console.error("Error fetching username:", error);
    }
  }

  return (
    <div className='main-area'>
      {loading && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Triangle color='#00BFFF' height={200} width={200} />
          <p>Loading...</p>
        </div>
      )}

      <Header />

      <div style={{ marginTop: 20 }} className='main-title-xl'>
        Inventory
      </div>
      <div className='tabPosition'>
        <CSSegmented
          options={["CLAIMED REWARDS", "AVAILABLE REWARDS"]}
          value={categoryItem}
          setValue={setCategoryItem}
        />
      </div>

      {!loading && (
        <>
          {/* Content for claimed rewards tab */}
          {categoryItem === "CLAIMED REWARDS" && (
            <div className='category-list-container' style={{ marginTop: 45 }}>
              <div className='category-list-area'>
                <div className='category-list-title'>
                  <div className='title' style={{ fontFamily: "Lalezar" }}>
                    CLAIMED REWARDS
                  </div>
                </div>
                <div className='category-list-content'>
                  {allUserItems.map((item, index) => {
                    if (item.claimed && item.sponsorName !== "N/A") {
                      return (
                        <PlayerDetailProductCard
                          key={item.id}
                          imageUrl={item.url}
                          itemName={item.name}
                          itemCreator={item.creatorName}
                          itemSponsor={item.sponsorName}
                          itemId={item.id}
                          irlDescription={item.irlRewardDescription}
                          claimURL={item.claimURL}
                          description={item.description}
                          isClickable={isPlayer}
                          claimed={item.claimed}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          )}

          {/* Content for available rewards tab */}
          {categoryItem === "AVAILABLE REWARDS" && (
            <div className='category-list-container' style={{ marginTop: 45 }}>
              <div className='category-list-area'>
                <div className='category-list-title'>
                  <div className='title' style={{ fontFamily: "Lalezar" }}>
                    AVAILABLE REWARDS
                  </div>
                </div>
                <div className='category-list-content'>
                  {allUserItems.map((item, index) => {
                    if (!item.claimed && item.sponsorName !== "N/A") {
                      return (
                        <PlayerDetailProductCard
                          key={item.id}
                          imageUrl={item.url}
                          itemName={item.name}
                          itemCreator={item.creatorName}
                          itemSponsor={item.sponsorName}
                          itemId={item.id}
                          irlDescription={item.irlRewardDescription}
                          claimURL={item.claimURL}
                          description={item.description}
                          isClickable={isPlayer}
                          claimed={item.claimed}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default PlayerDetailPage;
