"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletCore = void 0;
const abstract_wallet_1 = require("./abstract.wallet");
const ethers_1 = require("ethers");
const common_1 = require("../common");
const errors_1 = require("../errors");
const key_providers_1 = require("../key-providers");
const zbyte_common_1 = require("@zbyteio/zbyte-common");
const relay_client_1 = require("./relay.client");
const utils_1 = require("../utils");
const external_apis_1 = require("../external-apis");
class WalletCore extends abstract_wallet_1.AbstractWallet {
    networkConfig;
    keyProvider;
    accountAddress;
    userMetaData;
    constructor(walletProvider, networkConfig) {
        super(walletProvider);
        this.keyProvider = {
            serviceProviderName: "",
            addChain: async () => undefined,
            switchChain: async () => undefined,
            getProvider: () => undefined,
        };
        if (networkConfig.networkType == zbyte_common_1.MAINNET) {
            this.nativeNetworkConfig = (0, zbyte_common_1.getBlockchainNetwork)(zbyte_common_1.CHAIN_ID_MATIC_MAINNET);
        }
        this.networkConfig = networkConfig;
        this.accountAddress = "";
    }
    setNetwork = async (networkConfig) => {
        try {
            this.networkConfig = networkConfig;
            const defaultChainId = networkConfig.networkType == zbyte_common_1.MAINNET ? zbyte_common_1.CHAIN_ID_MATIC_MAINNET : zbyte_common_1.CHAIN_ID_MATIC_TESTNET;
            this.nativeNetworkConfig = (0, zbyte_common_1.getBlockchainNetwork)(defaultChainId);
            if (this._isProviderInitialized) {
                this.keyProvider.addChain(networkConfig);
                this.keyProvider.switchChain((0, utils_1.convertNumToHexString)(networkConfig.chainId));
            }
        }
        catch (error) {
            throw new errors_1.WalletError(errors_1.ErrorCode.WALLET_SET_NETWORK_FAILED, error);
        }
    };
    getNetwork = () => {
        return this.networkConfig;
    };
    getUserMetaData = () => {
        return this.userMetaData;
    };
    getDPLATTransferGasFee = async (recipientAddress, amount) => {
        try {
            const accountAddress = await this.getAddress();
            const gasEstimationReq = {
                toAddress: recipientAddress,
                amount,
                chainId: this.nativeNetworkConfig.chainId,
                fromAddress: accountAddress,
            };
            const estimateDetails = await (0, external_apis_1.estimateGasTransfer)(gasEstimationReq);
            return estimateDetails.gasRequired;
        }
        catch (error) {
            throw new errors_1.WalletError(errors_1.ErrorCode.ESTIMATE_DPLAT_TRANSFER_GAS_FAILED, error);
        }
    };
    getNFTTransferGasFee = async (contractHash, tokenId, recipientAddress, chainId) => {
        try {
            const accountAddress = await this.getAddress();
            const payload = {
                userAddress: accountAddress,
                functionSignature: "safeTransferFrom",
                functionABI: JSON.stringify(common_1.SAFE_TRANSFER_FROM_ABI),
                functionArgs: [accountAddress, recipientAddress, tokenId],
                value: "0",
            };
            const response = await (0, external_apis_1.invokeEstimateDetails)(contractHash, chainId, payload);
            return response.totalRequiredGasFee;
        }
        catch (error) {
            throw new errors_1.WalletError(errors_1.ErrorCode.ESTIMATE_NFT_TRANSFER_GAS_FAILED, error);
        }
    };
    sendToken = async (recipientAddress, amount) => {
        try {
            const relayClient = new relay_client_1.RelayClient(this, this.nativeNetworkConfig.chainId);
            relayClient.transferDplat(recipientAddress, amount);
        }
        catch (error) {
            throw new errors_1.WalletError(errors_1.ErrorCode.DPLAT_TRANSFER_FAILED, error);
        }
    };
    transferNFT = async (contractHash, tokenId, recipientAddress, chainId, gasRequired) => {
        try {
            const relayClient = new relay_client_1.RelayClient(this, this.nativeNetworkConfig.chainId);
            const address = await this.getAddress();
            const functionABIFragment = (0, utils_1.getFunctionABIFragment)("safeTransferFrom", contractHash, common_1.SAFE_TRANSFER_FROM_ABI, [address, recipientAddress, tokenId], chainId, address);
            return await relayClient.invokeContract(contractHash, "safeTransferFrom", functionABIFragment, chainId, zbyte_common_1.RelayOperations.NFT_TRANSFER, [address, recipientAddress, tokenId], gasRequired);
        }
        catch (error) {
            throw new errors_1.WalletError(errors_1.ErrorCode.NFT_TRANSFER_FAILED, error);
        }
    };
    getKeyProvider = async () => {
        this._checkWalletConnected();
        if (!this._isProviderInitialized) {
            const rpcUrls = (0, zbyte_common_1.getRPCNetworkUrls)(this.networkConfig.chainId);
            for (let retry = 0; retry < rpcUrls.length; retry++) {
                try {
                    this.keyProvider = await this.walletProvider.getKeyProvider(this.networkConfig);
                    const networkConfigs = (0, zbyte_common_1.getSupportedBlockchainNetworkList)();
                    networkConfigs.forEach((networkConfig) => {
                        if (networkConfig.chainId !== this.networkConfig.chainId) {
                            this.keyProvider.addChain(networkConfig);
                        }
                    });
                    break;
                }
                catch (error) {
                    console.error("getting error while using rpc url", this.networkConfig.networkRpcUrl);
                    if (retry < rpcUrls.length - 1) {
                        this.networkConfig.networkRpcUrl = rpcUrls[retry];
                        if (this.networkConfig.chainId == zbyte_common_1.CHAIN_ID_MATIC_MAINNET ||
                            this.networkConfig.chainId == zbyte_common_1.CHAIN_ID_MATIC_TESTNET) {
                            this.nativeNetworkConfig.networkRpcUrl = rpcUrls[retry];
                        }
                        console.info("retry number [ ", retry + 1, " ] using new rpc url : ", this.networkConfig.networkRpcUrl);
                        continue;
                    }
                    throw new errors_1.WalletError(errors_1.ErrorCode.GET_KEY_PROVIDER_FAILED, error);
                }
            }
        }
        return this.keyProvider;
    };
    isConnected = () => {
        return this.walletProvider.isConnected();
    };
    injectAuthVerifier = (value, isPopUp = false) => {
        if (this.walletProvider instanceof key_providers_1.Web3AuthProvider) {
            this.walletProvider.injectLoginParams(value, isPopUp);
        }
        else
            throw new errors_1.WalletError(errors_1.ErrorCode.NOT_IMPLEMENTED);
    };
    connect = async () => {
        try {
            this.userMetaData = await this.walletProvider.connect();
        }
        catch (error) {
            throw new errors_1.WalletError(errors_1.ErrorCode.WALLET_NOT_CONNECTED, error);
        }
    };
    getAddress = async () => {
        this._checkWalletConnected();
        if (this.accountAddress === "") {
            await this.getKeyProvider();
            const ethProvider = new ethers_1.ethers.providers.Web3Provider(this.keyProvider.getProvider());
            this.accountAddress = await ethProvider.getSigner().getAddress();
        }
        return this.accountAddress;
    };
    _checkWalletConnected = () => {
        if (this.isConnected())
            return;
        throw new errors_1.WalletError(errors_1.ErrorCode.WALLET_NOT_CONNECTED);
    };
    get _isProviderInitialized() {
        if (this.keyProvider.getProvider() !== undefined) {
            return true;
        }
        return false;
    }
    fetchTransactions = async (address, filter) => {
        const txnList = await (0, external_apis_1.fetchTransactionList)(address, filter);
        return (0, utils_1.convertTxnResponseToITransactions)(txnList);
    };
    signTypedData = async (txnMessage, chainId) => {
        const address = await this.getAddress();
        const params = [address, txnMessage];
        if (this.networkConfig.networkType != (0, zbyte_common_1.getBlockchainNetwork)(chainId).networkType) {
            throw new errors_1.WalletError(errors_1.ErrorCode.NETWORK_TYPE_MISMATCHED);
        }
        try {
            await this.keyProvider.switchChain((0, utils_1.convertNumToHexString)(chainId));
            const signature = await this.keyProvider
                .getProvider()
                .request({ method: "eth_signTypedData_v4", params: params });
            return signature;
        }
        catch (error) {
            throw new errors_1.WalletError(errors_1.ErrorCode.SIGNATURE_FAILED, error);
        }
    };
    batchSignTypedData = async (txnBatch) => {
        this._checkWalletConnected();
        const operationSigList = [];
        for (const element of txnBatch.transactions) {
            const txn = element;
            const signature = await this.signTypedData(JSON.stringify(txn.data), txn.data.domain.chainId);
            const opSign = { operationName: txn.metadata.subOperation, signature };
            console.info(`${txn.metadata.subOperation} txn got signed`);
            operationSigList.push(opSign);
        }
        return operationSigList;
    };
}
exports.WalletCore = WalletCore;
