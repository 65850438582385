import type { ThemeConfig } from "antd";

const SegmentedTheme: ThemeConfig = {
  components: {
    Segmented: {
      fontSize: 18,
      fontWeightStrong: 500,
      itemSelectedBg: "#F579FF",
      itemSelectedColor: "#0F1D40",
      itemColor: "#A4A9B6",
      trackPadding: "8px 16px",
      controlHeight: 45,
    },
  },
};

export default SegmentedTheme;
