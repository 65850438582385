import { FC } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";

interface IProps {
  isButton?: boolean;
  avatar: string;
  displayNames: string; // Update this type as per your data structure
  robloxId: string;
}

const MemberCard: FC<IProps> = ({ isButton = false, avatar, displayNames, robloxId }) => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/creator/${robloxId}`;
    navigate(path);
  };
  return (
    <div className='member-card' onClick={routeChange}>
      <img src={avatar} alt="Avatar" className="avatar" /> {/* Add avatar */}
      <div className='member-info-main'>
        <div className='member-title'>{displayNames}</div> {/* Assuming displayNames is an array */}
      </div>
    </div>
  );
};

export default MemberCard;
