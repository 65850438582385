import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import SponsorCard from "../../components/SponsorCard";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

// Adjust the Group interface to include items with isVisible and isCustomizable states
export interface Group {
  groupId: string;
  groupName: string;
  items: {
    id: string;
    url: string;
    isVisible: boolean;
    isCustomizable: boolean;
  }[];
}

function Gallery({
  robloxGroupname,
  bestSellers,
  allItems,
  groupImages,
  groupInfo,
  updateDatabase,
  getToggles,
  isCreator,
  creatorId,
}: {
  robloxGroupname: Group[];
  bestSellers: any[];
  allItems: any[];
  groupImages: any[];
  groupInfo: any[];
  updateDatabase: any;
  getToggles: any;
  isCreator: boolean;
  creatorId: string;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [toggles, setToggles] = useState<any[]>([]);

  useEffect(() => {
    // Fetch all toggles before rendering
    console.log(allItems);
    Promise.all(
      allItems[currentPage]?.map((item: any) => getToggles(item.id))
    ).then((toggles) => {
      setToggles(toggles);
    });
  }, [allItems, currentPage]);

  function getTogglesById(toggles: any, itemIdToFind: string) {
    // Find the item with the specified itemId
    const item = toggles.find(
      (item: any) => item && Number(item.itemId) === Number(itemIdToFind)
    );

    // If item is found, extract isVisible and isCustomizable properties
    if (item) {
      const isVisible = item.isVisible;
      const isCustomizable = item.isCustomizable;
      return { isVisible, isCustomizable };
    }

    // If item is not found, return default values
    return { isVisible: false, isCustomizable: false };
  }

  const handleNextPage = () => {
    if (currentPage < robloxGroupname.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Calculate the number of months since creation
  const calculateMonthsSinceCreation = (createdDate: string) => {
    const currentDate = new Date();
    const created = new Date(createdDate);
    const diff = currentDate.getTime() - created.getTime();
    const months = diff / (1000 * 60 * 60 * 24 * 30); // Approximate number of milliseconds in a month
    return Math.round(months);
  };

  return (
    <div className='main-area'>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "3.75rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 25,
            width: "67%",
            marginRight: 45,
            marginLeft: 45,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              type='default'
              style={{ marginRight: 15 }}
              onClick={handlePreviousPage}
            >
              <LeftOutlined />
            </Button>
            <div className='creator-head'>
              <div className='creator-title'>
                {(robloxGroupname[currentPage]?.groupName ?? "").toUpperCase()}
              </div>
              {robloxGroupname[currentPage]?.groupName !==
              "Player inventory" ? (
                <div className='creator-subtitle'>
                  ROBLOX GROUP: {robloxGroupname[currentPage]?.groupId}
                </div>
              ) : (
                <div className='creator-subtitle'></div>
              )}
            </div>{" "}
            <Button type='default' onClick={handleNextPage}>
              <RightOutlined />
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className='creator-card border-white-rad'
              style={{ overflow: "hidden", width: 750, height: 302 }}
            >
              <div
                style={{
                  width: "100%",
                  height: 302,
                  display: "flex",
                  alignItems: "center",
                  borderRight: "1px solid",
                }}
              >
                <img
                  src={groupImages[currentPage]}
                  alt='Profile'
                  className='profile-image'
                  style={{
                    height: 298,
                    objectFit: "cover",
                    width: 277,
                  }}
                />
              </div>
              <div className='creator-right'>
                <div className='container'>
                  {robloxGroupname[currentPage]?.groupName !==
                  "Player inventory" ? (
                    <div className='creator-card'>
                      <img
                        src={groupInfo[currentPage]?.avatarURL}
                        alt='Avatar'
                        className='avatar-image'
                        style={{ height: 150, paddingTop: 24 }}
                      />
                    </div>
                  ) : null}
                  <div className='item'>
                    <div className='item-big-container'>
                      <div className='three-column align-items'>
                        <div className='person'></div>
                        <div style={{ marginLeft: 10 }}>OWNED BY</div>
                      </div>
                      {robloxGroupname[currentPage]?.groupName !==
                      "Player inventory" ? (
                        <div className='three-column score-text'>
                          {groupInfo[currentPage].owner.displayName}
                        </div>
                      ) : (
                        <div className='creator-subtitle'> N/A</div>
                      )}
                      <div className='three-column'></div>
                    </div>
                  </div>
                  <div className='item'></div>
                  <div className='item right-border'>
                    <div className='item-big-container'>
                      <div className='three-column align-items'>
                        <div className='access'></div>
                        <div style={{ marginLeft: 10 }}>ACCESS</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <div className='three-column score-text'>
                          {robloxGroupname[currentPage]?.groupName !==
                          "Player inventory" ? (
                            <div className='three-column score-text'>
                              {" "}
                              {groupInfo[currentPage].publicEntryAllowed
                                ? "Public"
                                : "Private"}
                            </div>
                          ) : (
                            <div className='creator-subtitle'>N/A</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='item top-two right-border'>
                    <div className='item-big-container'>
                      <div className='three-column align-items'>
                        <div className='three-person'></div>
                        <div style={{ marginLeft: 10 }}>MEMBERS</div>
                      </div>
                      {robloxGroupname[currentPage]?.groupName !==
                      "Player inventory" ? (
                        <div className='three-column score-text'>
                          {groupInfo[currentPage].memberCount}
                        </div>
                      ) : (
                        <div className='creator-subtitle'>N/A</div>
                      )}
                    </div>
                  </div>
                  <div className='item right-border'>
                    <div className='item-big-container'>
                      <div className='three-column align-items'>
                        <div className='search'></div>
                        <div style={{ marginLeft: 10, fontSize: 12 }}>
                          CREATED
                        </div>
                      </div>
                      {robloxGroupname[currentPage]?.groupName !==
                      "Player inventory" ? (
                        <div className='three-column score-text long'>
                          {groupInfo[currentPage].created.split("T")[0]}
                        </div>
                      ) : (
                        <div className='creator-subtitle'>N/A</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='creator-sponsors-card' style={{ marginTop: "15vh" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: 45,
            marginBottom: 15,
          }}
        >
          {" "}
          <Button type='default' style={{ marginRight: 15 }}>
            <LeftOutlined />
          </Button>
          <div className='creator-head-title'>
            {"Available for Sponsors".toUpperCase()}
          </div>
          <Button type='default'>
            <RightOutlined />
          </Button>
        </div>
        <div className='creator-list-content'>
          {allItems[currentPage]?.map((item: any, index: number) => {
            if (isCreator || getTogglesById(toggles, item.id)?.isVisible) {
              return (
                <SponsorCard
                  updateDatabase={updateDatabase}
                  groupId={robloxGroupname[currentPage]?.groupId}
                  itemId={item.id}
                  itemName={(item.name as string).toUpperCase()}
                  key={index}
                  imageUrl={item.url}
                  buttonEnabled={isCreator}
                  isCustomizable={
                    getTogglesById(toggles, item.id)?.isCustomizable
                  }
                  isPublic={getTogglesById(toggles, item.id)?.isVisible}
                  itemInfo={{
                    itemPrice: item.price,
                    itemCreator: item.creatorName,
                    totalQuantity: item.totalQuantity.toString(),
                    unitsAvailableForConsumption: (
                      item.totalQuantity - item.unitsAvailableForConsumption
                    ).toString(),
                  }}
                  creatorId={creatorId}
                />
              );
            }
            return null;
          })}
        </div>
      </div>

      <div className='creator-sponsors-card' style={{ marginTop: "15vh" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginRight: 45,
            marginBottom: 15,
          }}
        >
          {" "}
          <Button type='default' style={{ marginRight: 15 }}>
            <LeftOutlined />
          </Button>
          <div className='creator-head-title'>
            {"Best Sellers".toUpperCase()}
          </div>
          <Button type='default'>
            <RightOutlined />
          </Button>
        </div>
        <div className='creator-list-content'>
          {bestSellers[currentPage]?.map((item: any, index: number) => {
            if (isCreator || getTogglesById(toggles, item.id)?.isVisible) {
              return (
                <SponsorCard
                  groupId={robloxGroupname[currentPage]?.groupId}
                  updateDatabase={updateDatabase}
                  itemId={item.id}
                  itemName={(item.name as string).toUpperCase()}
                  key={index}
                  imageUrl={item.url}
                  buttonEnabled={false}
                  isCustomizable={item.isCustomizable}
                  isPublic={item.isVisible}
                  itemInfo={{
                    itemPrice: item.price,
                    itemCreator: item.creatorName,
                    totalQuantity: item.totalQuantity.toString(),
                    unitsAvailableForConsumption: (
                      item.totalQuantity - item.unitsAvailableForConsumption
                    ).toString(),
                  }}
                  creatorId={creatorId}
                />
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
  );
}

export default Gallery;
