import "./style.scss";
import logo from "../../../assets/images/logo.png";
import { Button, Drawer, Dropdown, MenuProps } from "antd";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import BurgerMenu from "../../../assets/icons/BurgerMenu.svg";
import { KeycloakContext } from "../../../context/keycloak";

const Header = () => {
  const location = useLocation();
  console.log(location.pathname);
  const activeLink = (link: string) => {
    return location.pathname === link ? "active" : "";
  };
  const [open, setOpen] = useState<boolean>(false);
  const auth = useContext(KeycloakContext);
  const handleLogout = () => auth?.logoutSession();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      className: "dropdown-menu",
      label: (
        <a target='_blank' rel='noopener noreferrer' onClick={handleLogout}>
          Logout
        </a>
      ),
    },
  ];

  const userProfilePicUrl = localStorage.getItem("avatar") || "";

  return (
    <div className='header-area'>
      <div className='header-menu-area'>
        <ul>
          <li></li>
          {localStorage.getItem("playerType") !== "Sponsor" && (
            <li>
              <a
                className={`${activeLink(
                  "/players/" + localStorage.getItem("robloxId")
                )}`}
                href={"/players/" + localStorage.getItem("robloxId")}
              >
                Rewards
              </a>
            </li>
          )}
          <li>
            <a className={`${activeLink("/creators")}`} href='/creators'>
              Creators
            </a>
          </li>
          <li>
            <a className={`${activeLink("/rewards")}`} href='/rewards'>
              Catalog
            </a>
          </li>
        </ul>
      </div>
      <div className='header-logo-area'>
        <img src={logo} alt='' />
      </div>
      <div className='header-account-area'>
        <Dropdown menu={{ items }} placement='bottomLeft'>
          <div className='account-content'>
            <img
              src={userProfilePicUrl}
              alt='Profile Pic'
              className='profile-pic'
            />

            <div style={{ marginTop: 20 }}>
              {localStorage.getItem("username")}
            </div>
          </div>
        </Dropdown>

        <Button type='text' onClick={showDrawer} className='bg-menu-button'>
          <img src={BurgerMenu} alt='' />
        </Button>
      </div>

      <Drawer
        onClose={onClose}
        open={open}
        style={{
          backgroundColor:
            "linear-gradient(181deg, rgba(57, 49, 106, 0.00) -31.67%, #050022 99.07%)",
          color: "#fff",
        }}
      ></Drawer>
    </div>
  );
};

export default Header;
